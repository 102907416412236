import React, { createContext, useState, useEffect } from "react";
import axios from '../api/axios';
import DataContext from "../context/DataContext";
import { enqueueSnackbar } from 'notistack';
import { ErrorMsgs } from '../Data/Data'

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const USER_ACCESS_BY_ID = process.env.REACT_APP_API_USER_ACCESS_GET_USER_ACCESS_BY_USER_ID;
  const USER_URL = process.env.REACT_APP_API_SELLER_FIND_ALL_SELLERS;

  const [auth, setAuth] = useState({});
  const [userAccessControls, setUserAccessControls] = useState([]);
  const [adminAccess, setAdminAccess] = useState(false);
  const [partnerAccess, setPartnerAccess] = useState(false);
  const [addKeyAccess, setAddKeyAccess] = useState(false);
  const [manageKey, setManageKey] = useState(true);
  
  const [editKeyAccess, setEditKeyAccess] = useState(false);
  const [extendKeyAccess, setExtendKeyAccess] = useState(false);
  const [disableKeyAccess, setDisableKeyAccess] = useState(false);
  const [updateKeyAccess, setUpdateKeyAccess] = useState(false);
  const [enableKeyAccess, setEnableKeyAccess] = useState(false);
  const [resetPwAccess, setResetPwAccess] = useState(false);
  const [setupAccess, setSetupAccess] = useState(false);
  const [createCustAccess, setCreateCustAccess] = useState(false);
  const [transferCustAccess, setTransferCustAccess] = useState(false);
  const [users, setUsers] = useState([]);
  const [type, setType] = useState(0);
  const [hasCreatePartnerPermission, setHasCreatePartnerPermission] = useState(false);
  const [printAccess, setPrintAccess ] = useState(false);
  const [isKeyDeleted , setIsKeyDeleted ] = useState(false);
  const [reSellerReport , setResellerReport ] = useState(false);
  const [distributorReport , setDistributorReport ] = useState(false);
  const [sellerReports , setSellerReports ] = useState(false);
  const [DistributorFullports , setDistributorFullReports ] = useState(false);
  const [ActivationReports , setActivationReports ] = useState(false);
  
  const [reSellerReportPrint , setResellerReportPrint ] = useState(false);
  const [distributorReportPrint , setDistributorReportPrint ] = useState(false);
  const [sellerReportsPrint , setSellerReportsPrint ] = useState(false);
  const [DistributorFullportsPrint , setDistributorFullReportsPrint ] = useState(false);
  const [ActivationReportsPrint , setActivationReportsPrint ] = useState(false);

  let variant

  let Sellers = false;
  
  

  const actionPermissionMapping = {
    password: 'PASSWORD_RESET',
    login: 'LOGIN',
    edit: 'EDIT_KEY',
    extend: 'EXTEND_KEY',
    deactivate: 'DEACTIVATE_KEY',
    enable: 'ENABLE_KEY',
    keyAdd: 'PARTNERS_KEY_ADD',
    admin: 'ADMIN_TOOL',
    setup: 'SETUP_DOWNLOAD',
    update: 'UPDATE_UUID',
    newCustomer: 'CREATE_NEW_CUSTOMER',
    transferCustomer: 'TRANSFER_CUSTOMER',
    print: 'PRINT_ACCESS',
    keyDelete: 'KEY_DELETE_ACCESS',
    resellerReport: 'RESELLER_STOCK_REPORT',
    distributorReport: 'DISTRIBUTOR_STOCK_REPORT',
    SaleReport: 'SALLER_REPORT',

  };


  const fetchSellers = async (type) => {
    try {
      const response = await axios.get(USER_URL + `/${type}`);
      const status = response.data.status;
      const result = response.data.result;
      const msg = response.data.msg;
      if (result && result.length > 0) {
        // If result is not undefined and has items
        setHasCreatePartnerPermission(true);
        //console.log("Result Users ", result);
      } else {
        // If result is undefined or has no items
        setHasCreatePartnerPermission(false);
        //console.log("There are no users ");
      }
     // console.log("Result Access User Partner ", hasCreatePartnerPermission);
      // This might not return the updated state immediately due to the asynchronous nature of setState.
    } catch (err) {
      variant = 'error';
      if (!err.response) {
        enqueueSnackbar(ErrorMsgs[0].noResponse, { variant });
      } else if (err.response !== 403) {
        enqueueSnackbar(ErrorMsgs[0].fetchError, { variant });
      }
    }
  };
  
  const fetchUserAccessControls = async () => {
    try {

      const response = await axios.get(USER_ACCESS_BY_ID);
      
      const accessControls = response.data.result;
      
      const enabledAccessControls = accessControls.filter(access => access.access.status === true);
      
      fetchSellers(type);
      
      const permissions = {};
      let isAdmin = false;
      let isUser = false;
      let addKey = false;
      let editKey = false;
      let extendKey = false;
      let disableKey = false;
      let updateUUID = false;
      let enableUUID = false;
      let resetPw = false;
      let createCust = false;
      let transferCust = false;
      let setupDown = false;
      let print = false;
      let keyDelete = false;
      let resellerReport = false;
      let DistributorReport = false;
      let sallerReport = false;
      let distriFullReport = false;
      let ActiveReport = false;
      let resellerReportPrint = false;
      let DistributorReportPrint = false;
      let sallerReportPrint = false;
      let distriFullReportPrint = false;
      let ActiveReportPrint = false; 
      let printEnable = false;

      
      enabledAccessControls.forEach((access, index) => {
        const action = access.access.access;
        
        permissions[action] = access.access;

        switch (action) {
          case 'ADMIN_TOOL':
            isAdmin = true;
            break;
          case 'PARTNERS_KEY_ADD':
            addKey = true;
            break;
          case 'EDIT_KEY':
            editKey = true;
            break;
          case 'EXTEND_KEY':
            extendKey = true;
            break;
          case 'DEACTIVATE_KEY':
            disableKey = true;
            break;
          case 'UPDATE_UUID':
            updateUUID = true;
            break;
          case 'ENABLE_KEY':
            enableUUID = true;
            break;
          case 'PASSWORD_RESET':
            resetPw = true;
            break;
          case 'CREATE_NEW_CUSTOMER':
            createCust = true;
            break;
          case 'SETUP_DOWNLOAD':
            setupDown = true;
            break;
          case 'TRANSFER_CUSTOMER':
            transferCust = true;
            break;
          case 'REPORT_SELLER_KEY_PRINT':
            print = true;
            break;
          case 'KEY_DELETE_ACCESS':
            keyDelete = true;
            break;
          case 'REPORT_RESELLER_STOCK':
            resellerReport = true;
            break;
          case 'REPORT_DISTRIBUTOR_STOCK':
            DistributorReport = true;
            break;
          case 'REPORT_SELLER_DETAILS':
            sallerReport = true;
            break;
          case 'REPORT_DISTRIBUTOR_FULL_DETAILS':
            distriFullReport = true;
            break;
          case 'REPORT_ACTIVATION_KEY_DETAILS':
            ActiveReport = true;
            break;
          case 'REPORT_SELLER_DETAILS_PRINT':
            sallerReportPrint = true;
            break;
          case 'REPORT_RESELLER_STOCK_PRINT':
            resellerReportPrint = true;
            break;
          case 'REPORT_DISTRIBUTOR_STOCK_PRINT':
            DistributorReportPrint = true;
            break;
          case 'REPORT_DISTRIBUTOR_FULL_PRINT':
            distriFullReportPrint = true;
            break;
          case 'REPORT_ACTIVATION_KEY_PRINT':
            ActiveReportPrint = true;
            break;
          case 'PRINT_ACCESS':
            printEnable = true;
            break;
          default:
            Sellers = true;
            break;
        }   
      });
      //ActiveReportPrint

      setAdminAccess(isAdmin);
      setAddKeyAccess(addKey);
      setEditKeyAccess(editKey);
      setExtendKeyAccess(extendKey);
      setDisableKeyAccess(disableKey);
      setUpdateKeyAccess(updateUUID);
      setEnableKeyAccess(enableUUID);
      setResetPwAccess(resetPw);
      setSetupAccess(setupDown);
      setCreateCustAccess(createCust);
      setTransferCustAccess(transferCust);
      setType(type);
      setUserAccessControls(permissions);
      setPrintAccess(print);
      setIsKeyDeleted(keyDelete);
      setResellerReport(resellerReport);
      setDistributorReport(DistributorReport);
      setSellerReports(sallerReport);
      setDistributorFullReports(distriFullReport);
      setActivationReports(ActiveReport);
      setSellerReportsPrint(sallerReportPrint);
      setDistributorFullReportsPrint(distriFullReportPrint);
      setDistributorReportPrint(DistributorReportPrint);
      setResellerReportPrint(resellerReportPrint);
      setActivationReportsPrint(ActiveReportPrint);
      setPrintAccess(printEnable);
      
      
      return permissions;
    } catch (error) {
      console.error('Error fetching user access controls:', error);
      throw error;
    }
  };

  return (
    <AuthContext.Provider value={{
      auth, setAuth, adminAccess, partnerAccess, setPartnerAccess, setAdminAccess, isKeyDeleted, setIsKeyDeleted,
      fetchUserAccessControls, addKeyAccess, setAddKeyAccess, editKeyAccess, setEditKeyAccess, reSellerReport , setResellerReport , sellerReports , setSellerReports ,
      extendKeyAccess, setExtendKeyAccess, hasCreatePartnerPermission, setHasCreatePartnerPermission, distributorReport , setDistributorReport,
      disableKeyAccess, setDisableKeyAccess, updateKeyAccess, setUpdateKeyAccess, setType, type, printAccess, setPrintAccess ,
      enableKeyAccess, setEnableKeyAccess, resetPwAccess, setResetPwAccess, fetchSellers, manageKey, setManageKey, DistributorFullports , setDistributorFullReports,
      setupAccess, setSetupAccess, createCustAccess, setCreateCustAccess, transferCustAccess, setTransferCustAccess, ActivationReports , setActivationReports,
      reSellerReportPrint , setResellerReportPrint, distributorReportPrint , setDistributorReportPrint, sellerReportsPrint , setSellerReportsPrint ,
      DistributorFullportsPrint , setDistributorFullReportsPrint, ActivationReportsPrint , setActivationReportsPrint,
    
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
