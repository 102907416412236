import axios from "../../api/axios";
import React, { useEffect, useMemo, useState, useContext, useRef } from "react";
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { enqueueSnackbar } from 'notistack';
import { Variables } from '../../Data/Data';
import DataContext from '../../context/DataContext';
import AuthProvider from '../../context/AuthProvider';
import KeyGenData from '../../context/KeyGenData';
import { TablesFilterSeller, FilterByUsers } from "../../Data/Data"
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';

import * as RB from 'react-bootstrap';
import * as moment from 'moment';
import { Autocomplete, TextField } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import IconButton from '@mui/material/IconButton';
import PrintIcon from '@mui/icons-material/Print';
import { ErrorMsgs } from '../../Data/Data';
import "./Distributor.css";

const USER_URL = process.env.REACT_APP_API_SELLER_FIND_ALL_SELLERS;
const USER_KEYS = process.env.REACT_APP_API_USER_RELATED_DETAIL_HIERARCHY;

const ActivationReport = () => {
  const maxSelectableDate = moment().endOf('day');
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [sellerId, setSellerId] = useState(null);
  const [sellerDetails, setSellerDetails] = useState({});
  const [availableUsers, setAvailableUsers] = useState([]);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [filterText, setFilterText] = useState('');
  const [openSearch, setOpenSearch] = useState(false);
  const [searchClicked, setSearchClicked] = useState(false); // New state to track if search was clicked
  const autocompleteRef = useRef(null);
  const [flattenedData, setFlattenedData] = useState([]);
  const [searchedData, setSearchedData] = useState([]);
  const [showTotalRow, setShowTotalRow] = useState(true);
  const [type, setType] = useState(2);
  const [dataReady, setDataReady] = useState(false); 
  //const [fullname, setFullname] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

  const { fullName} = useContext(DataContext)
  const { ActivationReportsPrint } = useContext(AuthProvider)

  
  const customStyles = {
    rows: {
      style: {
        minHeight: '100px',
      },
    },
    headCells: {
      style: {
        borderStyle: 'solid',
        borderColor: '#40c4ff',
        borderWidth: '1px',
        borderCollapse: 'collapse',
        fontWeight: 'bold',
        padding: '5px',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#b2ebf2',
        height: '60px',
      },
    },
    cells: {
      style: {
        overFlowWrap: 'break-word',
        borderStyle: 'solid',
        borderColor: '#40c4ff',
        borderWidth: '1px',
        borderCollapse: 'collapse',
        padding: '5px',
        alignItems: 'center',
        justifyContent: 'center',
        height: '60px',
        width: '152px',
      },
    },
  };

  const tableStyle = {
    headCells: customStyles.headCells.style,
    rows: customStyles.rows.style,
    cells: customStyles.cells.style,
  };

  const fetchUsers = async (type, filter) => {
    try {
      const response = await axios.get(`${USER_URL}/${type}`, { params: { search: filter } });
      const { status, result, msg } = response.data;
      if (status) {
        if (result) {
          setUsers(result);
          setAvailableUsers(result.map(user => `${user.fname} ${user.lname}`));

        } else {
          setUsers([]);
        }
      } else {
        enqueueSnackbar(msg, { variant: 'error' });
      }
    } catch (err) {
      enqueueSnackbar(ErrorMsgs[0].fetchError, { variant: 'error' });
    }
  };

  const fetchAllUsers = async (type) => {
    try {
      const response = await axios.get(USER_URL + `/${type}`);
      const status = response.data.status;
      const result = response.data.result;
      const msg = response.data.msg;

      if (status) {
        if (result && result.length > 0) {
          setUsers(result);
          return result.map(user => user.id);
        } else {
          setUsers([]);

        }
      } else {
        setUsers([]);
        enqueueSnackbar(msg, { variant: 'error' });
      }
    } catch (err) {
      if (!err.response) {
        enqueueSnackbar(ErrorMsgs[0].noResponse, { variant: 'error' });
      } else if (err.response !== 403) {
        enqueueSnackbar(ErrorMsgs[0].fetchError, { variant: 'error' });
      }
    }
  };

  const fetchUserDetails = async (userId) => {
    try {
      
      const response = await axios.get(`${USER_KEYS}${userId}`);
      const { status, result, msg } = response.data;
      //console.log('Data', response);
      if (status) {
        setSellerDetails(response.data.result); 
        //console.log('seller data' , sellerDetails)
        const data = searchData(sellerDetails)
        setSearchedData(data)
        return result;
      } else {
        throw new Error(msg);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const fetchDistributorDetails = async (userIds) => {
    try {
      
      const userDetailsPromises = userIds.map(userId => fetchUserDetails(userId));
      const userDetailsArray = await Promise.all(userDetailsPromises);
      const validUserDetails = userDetailsArray.filter(details => details !== null);
      const data = flattenData(validUserDetails);
      setFlattenedData(data);
      return data;


    } catch (error) {
      setFlattenedData([]);


      enqueueSnackbar('Error fetching reseller details', { variant: 'error' });
    }
  };

  useEffect(() => {
    if (filterText) {
      fetchUsers(0, filterText);
      fetchAllUsers(0);
    } else {
      setUsers([]);
      setSellerId(null);
    }
  }, [filterText]);

  /*  const flattenData = (data) => {
     const flatten = (user, level = 0) => {
       const { userName, keyStock = {}, users = [] } = user;
       const { avPrimKeys = 0, avTempKeys = 0, crPrimKeys = 0, crTempKeys = 0, acPrimKeys = 0, acTempKeys = 0 } = keyStock;
   
       let result = [
         {
           userName,
           avPrimKeys,
           avTempKeys,
           crPrimKeys,
           crTempKeys,
           acPrimKeys,
           acTempKeys,
           level,
         },
       ];
   
       if (users.length > 0) {
         users.forEach((reseller) => {
           result = result.concat(flatten(reseller, level + 1));
         });
       }
   
       return result;
     };
   
     return flatten(data);
   };
   
  */

   const handleSort = (key) => {
    // Determine direction for sorting
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    // Update sortConfig state
    setSortConfig({ key, direction });
  };


 /*  const flattenData = (data) => {
    const flatten = (user, level = 0, parentDistributor = null, parentCity = '') => {
      const { userName, keyDetails = [], customerDetails = [], users = [], city = '' } = user;
      const currentCity = city || parentCity;
      
      // Merge key details with customer details based on custId
      const mergedDetails = keyDetails.map((key) => {
        const customer = customerDetails.find(cust => cust.custId === key.custId);
        return {
          ...key,
          ...customer,
          superDistributor: fullName.result.fname + " " + fullName.result.lname,
          distributorName: level === 0 ? userName : parentDistributor,
          resellerName: level > 0 ? userName : '',
          city: level > 0 ? currentCity : '',
          level
        };
      });
      
      let result = mergedDetails;
  
      // Recursively flatten nested users
      if (users.length > 0) {
        users.forEach((reseller) => {
          const flattenedReseller = flatten(reseller, level + 1, parentDistributor || userName, currentCity);
          result = result.concat(flattenedReseller);
        });
      }
  
      return result;
    };
  
    return data.flatMap(user => flatten(user));
  };
  
   */

  const flattenData = (data) => {
    const seenUUIDs = new Set();

    const flatten = (user, level = 0, parentDistributor = null, parentCity = '') => {
        const { userName, keyDetails = [], customerDetails = [], users = [], city = '' } = user;
        const currentCity = city || parentCity;

        // Merge key details with customer details based on custId
        const mergedDetails = keyDetails.map((key) => {
            const customer = customerDetails.find(cust => cust.custId === key.custId);
            const record = {
                ...key,
                ...customer,
                superDistributor: fullName.result.fname + " " + fullName.result.lname,
                distributorName: level === 0 ? userName : parentDistributor,
                resellerName: level > 0 ? userName : '',
                city: level > 0 ? currentCity : '',
                level
            };

            // Check for duplicate using UUID
            if (!seenUUIDs.has(record.uuid)) {
                seenUUIDs.add(record.uuid);
                return record;
            }

            return null; // Return null for duplicate records
        }).filter(record => record !== null); // Remove nulls (duplicates) from the result

        let result = mergedDetails;

        // Recursively flatten nested users
        if (users.length > 0) {
            users.forEach((reseller) => {
                const flattenedReseller = flatten(reseller, level + 1, parentDistributor || userName, currentCity);
                result = result.concat(flattenedReseller);
            });
        }

        return result;
    };

    return data.flatMap(user => flatten(user));
};


  /* const searchData = (data) => {
    const flatten = (user, level = 0, parentDistributor = null, parentCity = '') => {
      const { userName, keyDetails = [], customerDetails = [], users = [], city = '' } = user;
  
      const currentCity = city || parentCity;
      const distributorName = parentDistributor || userName;
      const resellerName = level > 0 ? userName : '';
  
      // Merge key details with customer details based on custId
      const mergedDetails = keyDetails.map((key) => {
        const customer = customerDetails.find(cust => cust.custId === key.custId) || {};
        return {
          ...key,
          ...customer,
          superDistributor: fullName.result.fname + " " + fullName.result.lname,
          distributorName: level === 0 ? userName : parentDistributor,
          resellerName: resellerName,
          city: level > 0 ? currentCity : '',
          level
        };
      });
  
      let result = mergedDetails;
  
      if (users.length > 0) {
        users.forEach((reseller) => {
          const flattenedReseller = flatten(reseller, level + 1, distributorName, currentCity);
          result = result.concat(flattenedReseller);
        });
      }
  
      return result;
    };
  
    return flatten(data);
  }; */

  const searchData = (data) => {
    const seenUUIDs = new Set();

    const flatten = (user, level = 0, parentDistributor = null, parentCity = '') => {
        const { userName, keyDetails = [], customerDetails = [], users = [], city = '' } = user;

        const currentCity = city || parentCity;
        const distributorName = parentDistributor || userName;
        const resellerName = level > 0 ? userName : '';

        // Merge key details with customer details based on custId
        const mergedDetails = keyDetails.map((key) => {
            const customer = customerDetails.find(cust => cust.custId === key.custId) || {};
            const record = {
                ...key,
                ...customer,
                superDistributor: fullName.result.fname + " " + fullName.result.lname,
                distributorName: level === 0 ? userName : parentDistributor,
                resellerName: resellerName,
                city: level > 0 ? currentCity : '',
                level
            };

            // Check for duplicate using UUID
            if (!seenUUIDs.has(record.uuid)) {
                seenUUIDs.add(record.uuid);
                return record;
            }

            return null; // Return null for duplicate records
        }).filter(record => record !== null); // Remove nulls (duplicates) from the result

        let result = mergedDetails;

        // Recursively flatten nested users
        if (users.length > 0) {
            users.forEach((reseller) => {
                const flattenedReseller = flatten(reseller, level + 1, distributorName, currentCity);
                result = result.concat(flattenedReseller);
            });
        }

        return result;
    };

    return flatten(data);
};

  

useEffect(() => {

}, [sellerId]);



  useEffect(() => {
    const loadDistributorsAndDetails = async () => {
      const userIds = await fetchAllUsers(0);
      //console.log('--- useEffect Triggered ---');
      //console.log('Type:', type);
      //console.log('From Date:', fromDate);
      //console.log('To Date:', toDate);
      //console.log('User IDs:', userIds);

      if (userIds.length > 0) {
        await fetchDistributorDetails(userIds);
        //console.log('Fetching distributor details...');
        setDataReady(true);
      }
    };

    //console.log('Loading distributors and details...');
    loadDistributorsAndDetails();
  }, [type]);

 
 

  const handleSearch = async () => {
    setSellerDetails({});
    if (sellerId) {
      setSearchClicked(true);

      try {
        const sellerDetails = await fetchUserDetails(sellerId);
        const data = searchData(sellerDetails);
        setSearchedData(data);
        setSellerDetails(sellerDetails);
        setOpenSearch(true);
      } catch (error) {
        setSellerId(null);
        setSearchedData([]);
        setSellerDetails({});
        setOpenSearch(false);
        enqueueSnackbar('No sellers found for this distributor', { variant: 'error' });
      }
    } else {
      setSellerId(null);
      setSearchedData([]);
      setSellerDetails({});
      setOpenSearch(false);
      enqueueSnackbar('Please select a Distributor ', { variant: 'error' });
    }
  };

  const handleResetDates = () => {
    setFromDate(new Date());
    setToDate(new Date());
    setSellerDetails({});
    setSellerId(null);
    setSearchClicked(false); // Reset the search clicked state
    
    setShowTotalRow(false);
  };

  const calculateTotals = (data) => {

    let totalAvPrimKeys = 0;
    let totalCrTempKeys = 0;
    let totalAcTempKeys = 0;
    let totalCrPrimKeys = 0;


    if (data && Array.isArray(data)) {

      data.forEach((row) => {
        if (row.avPrimKeys) totalAvPrimKeys += row.avPrimKeys;
        if (row.crTempKeys) totalCrTempKeys += row.crTempKeys;
        if (row.acTempKeys) totalAcTempKeys += row.acTempKeys;
        if (row.crPrimKeys) totalCrPrimKeys += row.crPrimKeys;
      });
    } else {
      console.error('Data is not defined or is not an array:', data);

      return { totalAvPrimKeys, totalCrTempKeys, totalAcTempKeys, totalCrPrimKeys };
    }

    return { totalAvPrimKeys, totalCrTempKeys, totalAcTempKeys, totalCrPrimKeys };
  };


  // Calculate totals
  const totals = calculateTotals(flattenedData);
  const searchTotals = calculateTotals(searchedData);


 
  useEffect(() => {
    // This effect runs after the first effect, so filteredItems should be updated
   // console.log('Seller ID:', sellerId);
    loadDataForPrint();
    loadDataForSearchPrint();
  }, [sellerId]);

  const loadDataForPrint = async () => {
    try {
      let dataToUse = [];
      
        const userIds = await fetchAllUsers(0); // Adjust the type if needed
        //console.log('User IDs:', userIds);
  
        if (userIds.length > 0) {
          dataToUse = await fetchDistributorDetails(userIds, fromDate, toDate);
          //console.log('Fetching distributor details...');
        }
      
      return dataToUse;
    } catch (error) {
      console.error('Error loading data for print:', error);
      enqueueSnackbar('Error loading data for print', { variant: 'error' });
      return [];
    }
  };
  

  const loadDataForSearchPrint = async () => {
    try {
      let dataToUse = [];

      if(sellerId && filterText !== ''){
        const data = await fetchUserDetails(sellerId, fromDate, toDate);
        dataToUse = searchData(data); // Assuming searchData processes the fetched data correctly
        //console.log('Fetching search data...');
      }
      
      return dataToUse;
    } catch (error) {
      console.error('Error loading data for print:', error);
      enqueueSnackbar('Error loading data for print', { variant: 'error' });
      return [];
    }
  };
  


  //const dataToUse = (searchClicked && Array.isArray(searchedData) && searchedData.length > 0) ? searchedData : flattenedData;

  
  const handlePrint = async () => {
    let dataToUse = [];
  
    if (sellerId ) {
      dataToUse = await loadDataForSearchPrint();
    } else {
      dataToUse = await loadDataForPrint();
    }
  
    // Check if dataToUse is empty or undefined
    if (!sellerId && !dataToUse || dataToUse.length === 0) {
      enqueueSnackbar('No data available for printing', { variant: 'error' });
      return;
    }
  
    // Format dates to YYYY-MM-DD
    const currentDate = new Date();
    const printDateTime = currentDate.toLocaleDateString() + ' ' + currentDate.toLocaleTimeString();
  
    // Open a new window for printing
    const printWindow = window.open('', '_blank', 'width=1000,height=800');
    if (printWindow) {
      // Generate rows for each seller
      let sellerDetailsContent = '';
  
      if (dataToUse.length === 0) {
        sellerDetailsContent = `
          <tr>
            <td colspan="12" style="text-align: center;">No data for this Distributor</td>
          </tr>
        `;
      } else {
        dataToUse.forEach((seller, index) => {
          const superDistributor = fullName.result.fname + ' ' + fullName.result.lname;
          const distributorName = seller.superDistributor;
  
          // Add row for the current seller
          sellerDetailsContent += `
            <tr>
              <td>${index + 1}</td>
              <td>${distributorName}</td>
              <td>${seller.sellerNames && seller.sellerNames.length > 0 ? seller.sellerNames.join(', ') : seller.distributorName || ''}</td>
              <td>${seller.uuid || ''}</td>
              <td>${seller.keyType || ''}</td>
              <td>${seller.expireDate || ''}</td>
              <td>${seller.installationDateTime || ''}</td>
              <td>${seller.companyName || ''}</td>
              <td>${seller.firstName || ''}</td>
              <td>${seller.lastName || ''}</td>
              <td>${seller.address || ''}</td>
              <td>${seller.country || ''}</td>
            </tr>
          `;
        });
      }
  
      const content = `
        <!DOCTYPE html>
        <html lang="en">
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Key Activation Report</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              margin: 0;
              padding: 0;
            }
            .container {
              width: 100%;
              padding: 20px;
            }
            h1 {
              font-size: 24px;
              font-weight: bold;
              margin-bottom: 20px;
            }
            table {
              width: 100%;
              border-collapse: collapse;
            }
            th, td {
              border: 1px solid #ccc;
              padding: 8px;
              text-align: left;
            }
            th {
              background-color: #f2f2f2;
            }
          </style>
        </head>
        <body>
          <div class="container">
            <h1>Key Activation Report</h1>
            <p>Time: <span id="fromDate">${printDateTime}</span></p>
            <table>
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  <th>Name of Distributor</th>
                  <th>Name of Reseller</th>
                  <th>License Key / UUID</th>
                  <th>Key Type</th>
                  <th>Expiry Date</th>
                  <th>Installation Date & Time</th>
                  <th>Company Name</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Address</th>
                  <th>Country</th>
                </tr>
              </thead>
              <tbody id="reportBody">
                ${sellerDetailsContent}
              </tbody>
            </table>
          </div>
        </body>
        </html>
      `;
  
      // Write content to the print window
      printWindow.document.open();
      printWindow.document.write(content);
      printWindow.document.close();
      // Trigger the printing process
      printWindow.print();
      // Close the print window after printing (optional)
      printWindow.close();
    } else {
      console.error('Failed to open print window');
    }
  };
  

 // Function to calculate row spans based on distributorName and resellerName
 const calculateRowSpans = (data) => {
  const spans = data.reduce((acc, row, index) => {
    const { distributorName, resellerName } = row;

    if (!acc.distributorName[distributorName]) {
      acc.distributorName[distributorName] = { count: 0, index };
    }
    acc.distributorName[distributorName].count += 1;

    if (!acc.resellerName[resellerName]) {
      acc.resellerName[resellerName] = { count: 0, index };
    }
    acc.resellerName[resellerName].count += 1;

    return acc;
  }, { distributorName: {}, resellerName: {} });

  return data.map((row, index) => {
    const { distributorName, resellerName } = row;
    return {
      ...row,
      distributorNameRowspan: spans.distributorName[distributorName].index === index ? spans.distributorName[distributorName].count : 0,
      resellerNameRowspan: spans.resellerName[resellerName].index === index ? spans.resellerName[resellerName].count : 0,
    };
  });
};

// Memoized processed data based on sorting configuration and row spans
const processedData = useMemo(() => {
  // Combine data based on searchClicked state
  const data = searchClicked ? searchedData : flattenedData;

  // Sort data based on sortConfig
  if (sortConfig.key) {
    data.sort((a, b) => {
      const keyA = sortConfig.key === 'country' ? a.country : 
                    sortConfig.key === 'companyName' ? a.companyName : a[sortConfig.key];
      const keyB = sortConfig.key === 'country' ? b.country : 
                    sortConfig.key === 'companyName' ? b.companyName : b[sortConfig.key];
      
      if (sortConfig.direction === 'asc') {
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      } else {
        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;
        return 0;
      }
    });
  }

  // Calculate row spans
  return calculateRowSpans(data);
}, [searchClicked, searchedData, flattenedData, sortConfig]);

  



  const subHeaderComponentMemo = useMemo(() => {
    return (
      <RB.Container fluid>
        <RB.Row className='bg-blue-s p-3 mb-2'>
          <RB.Col xl='2' lg='2' sm='2' xs='12' xxl='2'>
            <RB.Form.Select
              id='keyTypeId'
              onChange={(e) => setType(e.target.value)}
              value={type}
              disabled={isDisabled}
              style={{
                width: '100%',
                backgroundColor: 'transparent',
                border: '1px solid black',
                height: '55px',
              }}

            >
              <option disabled value=''>Filter By...</option>
              {FilterByUsers.map((options, index) => (
                <option key={index} value={options.value}>{options.select}</option>
              ))}
            </RB.Form.Select>
          </RB.Col>
          <RB.Col xl='2' lg='2' sm='2' xs='12' xxl='2'>
            <Autocomplete
              freeSolo
              id="search"
              options={availableUsers.map(seller => ({ label: seller, value: seller }))}
              getOptionLabel={option => option.label}
              onChange={(event, newValue) => {
                if (newValue) {
                  setFilterText(newValue.label);
                  const selectedSeller = users.find(user => `${user.fname} ${user.lname}` === newValue.label);
                  if (selectedSeller) {
                    setSellerId(selectedSeller.id);
                  }
                } else {
                  setFilterText('');
                  setSellerId(null);
                }
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="[Distributer Name]"
                  variant="outlined"
                  onChange={e => setFilterText(e.target.value)}
                  inputRef={autocompleteRef}
                />
              )}
            />
          </RB.Col>
         
          <RB.Col xl='2' lg='2' sm='2' xs='12' xxl='3'>
  <RB.Row className="d-flex justify-content-between">
    <RB.Col xs="auto">
      <RB.Button variant="outline-danger" id="clear-button" type="button" onClick={handleResetDates} style={{ padding: '15px 20px', fontSize: '16px' }}>
        Reset
      </RB.Button>
    </RB.Col>
    <RB.Col xs="auto">
      <RB.Button
        id="search-button"
        type="button"
        onClick={() => {
          if (sellerId && fromDate && toDate) {
            handleSearch();
          } else {
            enqueueSnackbar('Please select a Distributor ', { variant: 'error' });
          }
        }}
        style={{ padding: '15px 20px', fontSize: '16px' , }}
      >
        Search
      </RB.Button>
    </RB.Col>
    <RB.Col xs="auto">
     
    </RB.Col>
  </RB.Row>
</RB.Col>
<RB.Col xl='2' lg='2' sm='2' xs='12' xxl='3'>
  <RB.Row className="d-flex justify-content-between">
  <RB.Col xs="auto">
     
     </RB.Col>
     
    
    <RB.Col xs="auto">
    {ActivationReportsPrint && (
      <RB.Button id="print-button" type="button" onClick={handlePrint} style={{ padding: '15px 20px', fontSize: '16px' }}>
        <PrintIcon />
        Print
      </RB.Button>
    )}
    </RB.Col>
  </RB.Row>
</RB.Col>




        </RB.Row>

      </RB.Container>
    );
  }, [filterText, sellerId, fromDate, toDate, showTotalRow]);

  // Sortable header cell component
  const SortableHeaderCell = ({ field, label }) => {
    const { key, direction } = sortConfig;
    const isActive = key === field;

    // Handle click on header cell
    const handleClick = () => {
      handleSort(field);
    };

    return (
      <th
        style={{ ...customStyles.headCells.style, width: '200px', cursor: 'pointer' }}
        onClick={handleClick}
      >
        {label}
        {isActive && (
          <span style={{ marginLeft: '5px' }}>
            {direction === 'asc' ? '↑' : '↓'}
          </span>
        )}
      </th>
    );
  };

  // Table header JSX
  const tableHeader = (
    <thead className="sticky-header" style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
      <tr>
        <th style={{ ...customStyles.headCells.style, width: '80px' }}>Sr. No</th>
        <SortableHeaderCell field="superDistributor" label="Name of Distributor" />
        <SortableHeaderCell field="distributorName" label="Reseller Name" />
        <th style={{ ...customStyles.headCells.style, width: '149px' }}>License Key/ UUID</th>
        
        <th style={{ ...customStyles.headCells.style, width: '149px' }}>Key Type</th>
        
        <SortableHeaderCell field="expireDate" label="Expire Date" />
        <SortableHeaderCell field="installationDateTime" label="Installation Date & Time" />
        <SortableHeaderCell field="companyName" label="Company Name" />
        <th style={{ ...customStyles.headCells.style, width: '149px' }}>First Name</th>
        <th style={{ ...customStyles.headCells.style, width: '149px' }}>Last Name</th>
        <th style={{ ...customStyles.headCells.style, width: '149px' }}>Address</th>
        <SortableHeaderCell field="country" label="Country" />
      </tr>
    </thead>
  );

 // Table body JSX
const tableBody = (
  <tbody>
    {processedData.length === 0 ? (
      <tr>
        <td colSpan="12" style={{ textAlign: 'center', ...customStyles.cells.style }}>
          There is no data for this Distributor
        </td>
      </tr>
    ) : (
      processedData.map((row, index) => (
        <tr key={index} style={customStyles.rows.style}>
          <td style={customStyles.cells.style} title="Serial No">{index + 1}</td>
          <td style={customStyles.cells.style} title="Name of Distributor">{row.superDistributor}</td>
          <td style={customStyles.cells.style} title="Reseller Name">{row.distributorName}</td>
          <td style={customStyles.cells.style} title="License Key/ UUID">{row.uuid}</td>
          <td style={customStyles.cells.style} title="Key Type">{row.keyType}</td>
          <td style={customStyles.cells.style} title="Expire Date">{row.expireDate}</td>
          <td style={customStyles.cells.style} title="Installation Date & Time">{row.installationDateTime}</td>
          <td style={customStyles.cells.style} title="Customer's Company Name">{row.companyName}</td>
          <td style={customStyles.cells.style} title="Customer's First Name">{row.firstName}</td>
          <td style={customStyles.cells.style} title="Customer's Last Name">{row.lastName}</td>
          <td style={customStyles.cells.style} title="Customer's Address">{row.address}</td>
          <td style={customStyles.cells.style} title="Customer's Country">{row.country}</td>
        </tr>
      ))
    )}
  </tbody>
);

  return (
    <>
      {/* Your DataTable or other components */}
      <DataTable
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        striped
        dense
        className="custom-datatable"
      />
      <RB.Container fluid>
        <RB.Row style={{ marginRight: '-28px' }}>
          <RB.Col className="col-table" style={{ marginLeft: '-10px', marginRight: '0px', height: '600px', overflow: 'hidden' }}>
          <div style={{ height: '500px', overflowY: 'auto' }}>
            <RB.Table striped bordered hover responsive>
              {tableHeader}
 
              {tableBody}
              
            </RB.Table>
            </div>
          </RB.Col>
        </RB.Row>
      </RB.Container>
    </>
  );
};

export default ActivationReport;