import React, { useRef, useState, useEffect, useCallback , useContext } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBRadio } from 'mdb-react-ui-kit';
import Form from 'react-bootstrap/Form';
import { Typeahead } from 'react-bootstrap-typeahead';
import TypeAheadClear from '../TypeAheadClear';
import { useNavigate, useLocation } from 'react-router-dom';
import './Reg.css';
import axios from '../../api/axios';
import Button from 'react-bootstrap/Button';
import SnackbarAlerts from '../SnackbarAlerts';
import { UserAccess, ErrorMsgs } from '../../Data/Data';
import { enqueueSnackbar } from 'notistack';
import DataContext from '../../context/DataContext';
import AuthProvider from '../../context/AuthProvider';
import { BiEdit } from 'react-icons/bi';
import InputGroup from 'react-bootstrap/InputGroup';
import useUserType from '../../hooks/useUserType';

/* const POSTALCODE_REGEX = /^\d{3,6}$/; */
const NAME_REGEX = /^.{1,40}$/;
const SURNAME_REGEX = /^.{0,40}$/;
const TELEPHONE_REGEX = /^\d{10,15}$/
const MOBILE_REGEX = /^\d{10,15}$/
const FAX_REGEX = /^\d{10,15}$/
const EMAIL_REGEX = /^[a-z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?(?:\.[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?)*$/;
const PWD_REGEX = /^.{6,10}$/;
const CUSTOMER_REG_URL = process.env.REACT_APP_API_CUSTOMER_SAVE;
const USER_REG_URL = process.env.REACT_APP_API_USER_SAVE;
const COUNTRY_URL = process.env.REACT_APP_API_COUNTRY_FIND_ALL;
//const USER_TYPE_URL = process.env.REACT_APP_API_USER_TYPE_FIND_ALL;
const INDUSTRY_URL = process.env.REACT_APP_API_INDUSTRY_FIND_ALL;
const CUSTOMER_BY_ID = process.env.REACT_APP_API_CUSTOMER_FIND_BY_ID;
const USER_BY_ID = process.env.REACT_APP_API_USER_FIND_BY_ID;
const UPDATE_CUSTOMER = process.env.REACT_APP_API_CUSTOMER_UPDATE;
const UPDATE_USER = process.env.REACT_APP_API_USER_UPDATE;
//const ACCESS_BY_ACCESS = process.env.REACT_APP_API_USER_ACCESS_GET_USER_ACCESS_BY_ACCESS;
const RESET_PASSWORD = process.env.REACT_APP_API_LOGIN_RESET_PASSWORD;
const EMAIL_CHECK = process.env.REACT_APP_API_LOGIN_CHECK_EMAIL;
//const USER_ACCESS_BY_ID = process.env.REACT_APP_API_USER_ACCESS_GET_USER_ACCESS_BY_USER_ID;
const CHECK_EMAIL_URL = `${process.env.REACT_APP_API_URL}/api/login/checkEmail`;

const statusOption = [{ status: true, name: 'Active' }, { status: false, name: "De-active" }]

function Reg() {

  function useValidation(value, regex) {
    const [isValid, setIsValid] = useState(false);
    const validate = () => {
      setIsValid(regex.test(value));
    };
    return [isValid, validate];
  }

  let variant = 'success'

  const userCreateType = UserAccess.userCreateType;
  const navigate = useNavigate()
  const location = useLocation()
  const from = location.state;
  const [type, setType] = useState('')
  const [id, setId] = useState(0)
  const userTypeRef = useRef(null);
  const industryRef = useRef(null);
  const countryRef = useRef(null);
  const statusRef = useRef(null);
  const { isEmpty } = useContext(DataContext)
  const { resetPwAccess } = useContext(AuthProvider)
  const allowedTypes = useUserType();
  const [fullAddress, setFullAddress] = useState('');
  const [newDob, setNewDob] = useState('');


  const [userType, setUserType] = useState([]);
  const [userTypeValue, setUserTypeValue] = useState('');
  const [userTypeId, setUserTypeId] = useState(0)
  const [validUserType, setValidUserType] = useState(false);
  const [userTYpeFocus, setUserTypeFocus] = useState(false)

  const [vatCode, setVatcode] = useState('');
  const [ValidVatCode, setValidVatCode] = useState(false);
  const [VatCodeFocus, setVatCodeFocus] = useState(false);
  //const [userProvidedDob, setUserProvidedDob] = useState(false);

  const [Fname, setFname] = useState('');
  const [validFname, setValidFname] = useState(false);
  const [FnameFocus, setFNameFocus] = useState(false);
  //const [isValidFname, validateFname] = useValidation(Fname, NAME_REGEX);

  const [surname, setSurname] = useState('');
  const [validSurname, setValidSurname] = useState(false);

  const [dob, setDob] = useState('');
  const fifteenYearsAgo = new Date();
  fifteenYearsAgo.setFullYear(fifteenYearsAgo.getFullYear() - 15);
  fifteenYearsAgo.setHours(0, 0, 0, 0); //

  const [companyName, setCompanyName] = useState('')
  const [companyFocus, setCompanyFocus] = useState(false)

  const [statuz, setStatuz] = useState([]);
  const [statusStatus, setStatusStatus] = useState(null);
  const [statusFocus, setStatusFocus] = useState(false)
  const [validStatus, setValidStatus] = useState(false)

  const [gender, setGender] = useState('');

  const [industry, setIndustry] = useState([]);
  const [industryOptions, setIndustryOptions] = useState([]);
  const [industryId, setIndustryId] = useState(0);
  const [validIndustry, setValidIndustry] = useState(false);
  const [industryFocus, setIndustryFocus] = useState(false)

  const [number, setNumber] = useState('');
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [cityFocus, setCityFocus] = useState(false)

  const [postalcode, setPostalcode] = useState('');/*
  const [validpostalcode, setValidPostalcode] = useState(false); */
  const [postalcodeFocus, setPostalcodeFocus] = useState(false);/*
  const [isPostalCodeValid, validatePostalCode] = useValidation(postalcode, POSTALCODE_REGEX); */
  const [province, setProvince] = useState('');

  const [country, setCountry] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [countryId, setCountryId] = useState(0);
  const [validCountry, setValidCountry] = useState(false);
  const [countryFocus, setCountryFocus] = useState(false);

  const [telephone, setTelephone] = useState('');
  const [validTelephone, setValidTelephone] = useState(false);
  const [telephoneFocus, setTelephoneFocus] = useState(false);
  //const [isTelephoneValid, validateTelephone] = useValidation(telephone, TELEPHONE_REGEX);

  const [mobile, setMobile] = useState('');
  const [validMobile, setValidMobile] = useState(false);
  const [mobileFocus, setMobileFocus] = useState(false);
 // const [isMobileValid, validateMobile] = useValidation(mobile, MOBILE_REGEX);

  const [fax, setFax] = useState('');
  const [validFax, setValidFax] = useState(false);
  //const [faxFocus, setfaxFocus] = useState(false);
  //const [isFaxValid, validateFax] = useValidation(fax, FAX_REGEX);

  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);
  //const [isEmailValid, validateEmail] = useValidation(email, EMAIL_REGEX);
  const [customer, setCustomer] = useState('Customer');
  const [usedEmail, setUsedEmail] = useState(false)

  const [errMsg, setErrMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [severity, setServerity] = useState("error");
  const [success, setSuccess] = useState(false);
  const [open, setOpen] = useState(false);
  const [sentRequest, setSendRequest] = useState(false)

  const [password, setPassword] = useState('')
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);
  const [editPw, setEditPw] = useState(false)
  //const [buttonDisable, setButtonDisable] = useState(false)
  //const [userAccessControls, setUserAccessControls] = useState([]);

  //console.log('Rendering with values:', { number, street, city, postalcode, province });

  // Debounce function
function debounce(func, delay) {
  let timeoutId;
  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
}


  const fetchIndustries = async () => {
    try {
      const response = await axios.get(INDUSTRY_URL + `/1`);
      const status = response.data.status;
      const result = response.data.result;
      if (status) {
        setIndustryOptions(result);
      } else {
        setIndustryOptions([]);
      }
    } catch (err) {
      variant = 'error'
      if (!err.response) {
        enqueueSnackbar(ErrorMsgs[0].noResponse, { variant })
      } else if (err.response !== 403) {
        enqueueSnackbar(ErrorMsgs[0].fetchError, { variant })
      }
    }
  }

  const fetchCountries = async () => {
    try {
      const response = await axios.get(COUNTRY_URL + `1`);
      const status = response.data.status;
      const result = response.data.result;
      if (status) {
        setCountryOptions(result);
      } else {
        setCountryOptions([]);
      }
    } catch (err) {
      variant = 'error'
      if (!err.response) {
        enqueueSnackbar(ErrorMsgs[0].noResponse, { variant })
      } else if (err.response !== 403) {
        enqueueSnackbar(ErrorMsgs[0].fetchError, { variant })
      }
    }
  }

  useEffect(() => {
    // Get the current date in the format 'MM/DD/YYYY'
    const currentDate = new Date().toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    setDob('');
    setNewDob('');
  }, []);

  const formatDate = (inputDate) => {
    const dateParts = inputDate.split('-');
    //console.log("Year:", dateParts[0]);
    //console.log("Month:", dateParts[1]);
    //console.log("Date:", dateParts[2]);
    return `${dateParts[0]}-${dateParts[1]}-${dateParts[2]}`;
  };

  useEffect(() => {
    document.title = 'Register';
    fetchIndustries();
    fetchCountries();
  }, []);

  useEffect(() => {

    if (from.length === 1) {
      setType(from[0]);
    } else if (from.length === 2) {
      setType(from[0])
      setId(from[1]);
    }
    if (type === 'customer' && id !== 0) {
      // Get customer by ID to update
      const getCustomer = async () => {
        try {
          const response = await axios.get(CUSTOMER_BY_ID + `${id}`);
          const { status, result, msg } = response.data;
          if (status) {
            const addressArray = result.address.split(",").map(item => item.trim());
            //console.log("response ", addressArray);
            // Set address components 
            setNumber(addressArray[0] || '');
            setStreet(addressArray[1] || '');
            // Check if index 2 or 3 is present and set accordingly
            if (addressArray[2] === "" && addressArray.length > 5) {
              setStreet(addressArray[3] || '');
            } else {
              setStreet(addressArray[2] || '');
            }
            // Set city
            if (addressArray[4] === "") {
              setCity(addressArray[5] || '');
            } else {
              setCity(addressArray[4] || '');
            }
            if (addressArray.length === 5) {
              setCity(addressArray[2] || '');
              setStreet(addressArray[''] || '');
            }
            setFullAddress(`${addressArray[0] || ''} ${addressArray[1] || ''}`);
            // Check the array length to determine postal code and province indices
            const postalCodeIndex = addressArray.length === 7 ? 5 : 6;
            const provinceIndex = addressArray.length === 7 ? 6 : 7;
            // Set postal code and province
            setPostalcode(addressArray[postalCodeIndex] || '');
            setProvince(addressArray[provinceIndex] || '');
            // Set other form fields
            setCompanyName(result.companyName || '');
            setUserTypeId(result.idUserType);
            setVatcode(result.vatNo || '');
            setFname(result.fname || '');
            setSurname(result.lname || '');
            //setDob(formatDate(result.dob)) || setNewDob(result.newDob || '');
            if (result.dob) {
              setDob(formatDate(result.dob));
              setNewDob(result.newDob || '');
            }
            setGender(result.gender || '');
            setFax(result.faxNo || '');
            setStatusStatus(result.status || '');
            setIndustryId(result.idIndustry);
            setCountryId(result.idCountry);
            setTelephone(result.tpNo || '');
            setMobile(result.mobileNo || '');
            setEmail(result.email || '');
          } else {
            variant = 'error';
            enqueueSnackbar(msg, { variant });
          }
        } catch (err) {
          variant = 'error';
          if (!err.response) {
            enqueueSnackbar(ErrorMsgs[0].customNoResponse, { variant });
          } else if (err.response !== 403) {
            enqueueSnackbar(ErrorMsgs[0].fetchError, { variant });
          }
        }
      };
      getCustomer();

    }

    if (type === 'user' && id !== 0) {
      //Get user by ID to update
      const getCustomer = async () => {
        try {
          const response = await axios.get(USER_BY_ID + `${id}`);
          const status = response.data.status
          const result = response.data.result
          const msg = response.data.msg
          if (status) {
            if (result.address) {
              const addressArray = result.address.split(",").map(item => item.trim());
              setNumber(addressArray[0] || '');
              setStreet(addressArray[1] || '');
              // Check if index 2 or 3 is present and set accordingly
              if (addressArray[2] === "" && addressArray.length > 5) {
                setStreet(addressArray[3] || '');
              } else {
                setStreet(addressArray[2] || '');
              }
              // Set city
              if (addressArray[4] === "") {
                setCity(addressArray[5] || '');
              } else {
                setCity(addressArray[4] || '');
              }
              if (addressArray.length === 5) {
                setCity(addressArray[2] || '');
                setStreet(addressArray[''] || '');
              }
              setFullAddress(`${addressArray[0] || ''} ${addressArray[1] || ''}`);
              // Check the array length to determine postal code and province indices
              const postalCodeIndex = addressArray.length === 7 ? 5 : 6;
              const provinceIndex = addressArray.length === 7 ? 6 : 7;
              // Set postal code and province
              setPostalcode(addressArray[postalCodeIndex] || '');
              setProvince(addressArray[provinceIndex] || '');
            }
            if (result.companyName) { setCompanyName(result.companyName) }
            setUserTypeId(result.idUserType);
            setVatcode(result.vatNo);
            setFname(result.fname);
            setSurname(result.lname);
            if (result.dob) {
              setDob(formatDate(result.dob));
              setNewDob(result.newDob || '');
            }

            setGender(result.gender)
            if (result.faxNo) {
              setFax(result.faxNo)
            }
            setStatusStatus(result.status)
            setCountryId(result.idCountry);
            setTelephone(result.tpNo);
            setMobile(result.mobileNo);
            setEmail(result.email);
          } else {
            variant = 'error'
            enqueueSnackbar((msg), { variant })
          }
        } catch (err) {
          variant = 'error'
          if (!err.response) {
            enqueueSnackbar(ErrorMsgs[0].noResponse, { variant })
          } else if (err.response !== 403) {
            enqueueSnackbar(ErrorMsgs[0].fetchError, { variant })
          }
        }
      }
      getCustomer();
    }
  }, [type, id, from])

  //set default userType option to update`
  useEffect(() => {
    if ((allowedTypes).find(option => option.id === userTypeId)) {
      setUserType([(allowedTypes).find(option => option.id === userTypeId)]);
    }
  }, [allowedTypes, userTypeId]);

  //console.log("allowedTypes ", allowedTypes);
  //console.log("userTypeId ", userTypeId);

  //set default industry option to update
  useEffect(() => {
    if ((industryOptions).find(option => option.id === industryId)) {
      setIndustry([(industryOptions).find(option => option.id === industryId)]);
    }
  }, [industryOptions, industryId]);

  //set default status option to update
  useEffect(() => {
    if ((statusOption).find(option => option.status === statusStatus)) {
      setStatuz([(statusOption).find(option => option.status === statusStatus)]);
    }
  }, [statusStatus]);

  //set default country option to update
  useEffect(() => {
    if (countryOptions) {
      if ((countryOptions).find(option => option.id === countryId)) {
        setCountry([(countryOptions).find(option => option.id === countryId)]);
      }
    }
  }, [countryOptions, countryId]);

  const handleNumberChange = (e) => {
    const value = e.target.value;
    setNumber(value);
    setFullAddress(`${value} ${street}`);
  };

  //set default value in radio button to update
  useEffect(() => {
    if (gender === 'Male' || gender === 'M') {
      document.getElementById('inlineRadio1').checked = true;
    }
    if (gender === 'Female' || gender === 'F') {
      document.getElementById('inlineRadio2').checked = true;
    }
  }, [gender]);

  //validate user type
  useEffect(() => {
    if (type === "user" && userType.length === 0) {
      setValidUserType(false);
    } else {
      setValidUserType(true);
    }
  }, [userType, type])

  //check whether customer is empty for validation
  const validateUserType = () => {
    if (validUserType) {
      return true
    } else {
      return false
    }
  }

  //validate status for update
  useEffect(() => {
    if (id !== 0 && statuz.length === 0) {
      setValidStatus(false);
    } else {
      setValidStatus(true);
    }
  }, [id, type, statuz])

  //validate first name
  useEffect(() => {
    setValidFname(NAME_REGEX.test(Fname));
  }, [Fname])

  //validate last name
  useEffect(() => {
    setValidSurname(SURNAME_REGEX.test(surname));
  }, [surname])

  //validate industry
  useEffect(() => {
    if (type === "customer" && industry.length === 0) {
      setValidIndustry(false);
    } else {
      setValidIndustry(true);
    }
  }, [industry, type])

  //check whether industry is false for validation
  const validateIndustry = () => {
    if (validIndustry) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    /* console.log("Company Name", companyName );
    console.log("F Name", Fname );
    console.log("L Name", surname );
    console.log("VAT", vatCode );
    console.log("BDate", dob );
    console.log("Gender", gender );
    console.log("Industry", industry );
    console.log("Status", statuz );
    console.log("Address", fullAddress );
    console.log("Country", country );
    console.log("Mobile", mobile );
    console.log("Email", email );  */



  }, [companyName, Fname, surname, vatCode, dob, gender, industry, statuz, fullAddress, country, mobile, email]);




  //validate country
  useEffect(() => {
    if (country.length === 0) {
      setValidCountry(false);
    } else {
      setValidCountry(true);
    }
  }, [country])

  //check whether country is false for validation
  const validateCountry = () => {
    if (validCountry) {
      return true
    } else {
      return false
    }
  }

  //validate telephone
  useEffect(() => {
    setValidTelephone(TELEPHONE_REGEX.test(telephone));
  }, [telephone])

  //validate mobile
  useEffect(() => {
    setValidMobile(MOBILE_REGEX.test(mobile));
  }, [mobile])

  //validate fax
  useEffect(() => {
    setValidFax(FAX_REGEX.test(fax));
  }, [fax])

  //validate email
  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email));
  }, [email])

  const checkEmailInDB = async (email) => {
    if (EMAIL_REGEX.test(email)) {
      setLoading(true);
      setErrMsg('');
      try {
        const response = await axios.post(CHECK_EMAIL_URL, { uname: email });
        const status = response.data.status;
        if (!status) {
          //enqueueSnackbar('Email is valid and unique!', { variant: 'success' });
          setUsedEmail(false);
        } else {
          setUsedEmail(true);
          enqueueSnackbar('This email already has an account.', { variant: 'error' });

        }
      } catch (err) {
        if (!err.response) {
          enqueueSnackbar('No response from server', { variant: 'error' });
        } else if (err.response.status !== 403) {
          enqueueSnackbar('An error occurred while checking the email', { variant: 'error' });
        }
      } finally {
        setLoading(false);
      }
    }
  };
  
  const debouncedCheckEmail = useCallback(debounce(checkEmailInDB, 500), []);
  
  useEffect(() => {
    if (validEmail) {
      debouncedCheckEmail(email);
    }
  }, [usedEmail, email, debouncedCheckEmail]);
  

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(password));
  }, [password])
  //when user changes the state of inputs error msg will be displayed
  useEffect(() => {
    setErrMsg('');
  }, [postalcode, Fname, surname, telephone, fax, email])

  //get UserTypeValue type value when UserTypeValue type changes
  useEffect(() => {
    if (userType.length !== 0) {
      setUserTypeValue(userType[0].type);
    }
  }, [userType])

  //handle submit method
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSendRequest(true)

    if (!validTelephone && !isEmpty(telephone)) {
      setSendRequest(false)
      return
    }

    let countryId = country[0].id;
    let address = fullAddress + ", " + street + ", " + city + ", " + postalcode + ", " + province;

    if (type === "customer") {

      let industryId = industry[0].id;
      //Data to register new customer
      const newCustomer = {
        tpNo: telephone, mobileNo: mobile, address, vatNo: vatCode,
        idIndustry: industryId, email, dob, gender, fname: Fname,
        lname: surname, idCountry: countryId, companyName
      }

      try {
        const response = await axios.post(CUSTOMER_REG_URL, newCustomer);
        const status = response.data.status
        if (status) {
          navigate('/customers', { state: "save success" });
          variant = 'success'
          enqueueSnackbar('Customer Registration Successful!', { variant })
        } else {
          variant = 'error'
          enqueueSnackbar('Customer Registration Failed!', { variant })
        }
      } catch (err) {
        setSendRequest(false)
        variant = 'error'
        if (!err.response) {
          enqueueSnackbar(ErrorMsgs[0].noResponse, { variant })
        } else if (err.response !== 403) {
          enqueueSnackbar('Customer Registration Failed!', { variant })
        }
      }
    } else if (type === "user") {

      try {
        const response = await axios.post(EMAIL_CHECK, { uname: email });
        const status = response.data.status
        if (status) {
          setUsedEmail(true)
          setSendRequest(false)
          return
        } else {
          setUsedEmail(false)
          setSendRequest(false)
        }
      } catch (err) {
        setSendRequest(false)
        variant = 'error'
        if (!err.response) {
          enqueueSnackbar(ErrorMsgs[0].noResponse, { variant })
        } else if (err.response !== 403) {
          enqueueSnackbar('!', { variant })
        }
      }

      let userTypeValueId = userType[0].id;
      let userTypeType = userType[0].type;
      const newUser = {
        fname: Fname, lname: surname, address, tpNo: telephone, mobileNo: mobile,
        faxNo: fax, email, dob, vatNo: vatCode, userType: { id: userTypeValueId, type: userTypeType },
        idCountry: countryId, companyName
      }

      try {
        const response = await axios.post(USER_REG_URL, newUser);
        const status = response.data.status
        if (status) {
          navigate('/partners', { state: "save success" });
          variant = 'success'
          enqueueSnackbar('User Registration Successful!', { variant })
        } else {
          variant = 'error'
          enqueueSnackbar('User Registration Failed!', { variant })
        }
      } catch (err) {
        setSendRequest(false)
        variant = 'error'
        if (!err.response) {
          enqueueSnackbar(ErrorMsgs[0].noResponse, { variant })
        } else if (err.response !== 403) {
          enqueueSnackbar('User Registration Failed!', { variant })
        }
      }
    }
  }

  useEffect(() => {
    //console.log('Updating values:', { number, street, city, postalcode, province });
  }, [number, street, city, postalcode, province]);

  //update a user/customer
  const handleUpdate = async (e) => {
    e.preventDefault();
    setSendRequest(true)

    if (!validTelephone && !isEmpty(telephone)) {
      setSendRequest(false)
      return
    }
    let countryId = country[0].id;
    let address = number + ", " + street + ", " + city + ", " + postalcode + ", " + province;
    let statusValue = statuz[0].status;

    //update a customer
    if (type === "customer") {

      let industryId = industry[0].id;
      //data to update a customer
      const updateCustomer = {
        id: id, tpNo: telephone, mobileNo: mobile, address, vatNo: vatCode,
        idIndustry: industryId, email, dob, gender, fname: Fname, /* faxNo:fax, */
        lname: surname, idCountry: countryId, status: statusValue, companyName
      }

      try {
        const response = await axios.put(UPDATE_CUSTOMER, updateCustomer);
        const status = response.data.status
        if (status) {
          navigate('/customers', { state: "update success" });
          variant = 'success'
          enqueueSnackbar('Customer Update Successful!', { variant })
        } else {
          variant = 'error'
          enqueueSnackbar('Customer Update Failed!', { variant })
        }
      } catch (err) {
        setSendRequest(false)
        variant = 'error'
        if (!err.response) {
          enqueueSnackbar(ErrorMsgs[0].noResponse, { variant })
        } else if (err.response !== 403) {
          enqueueSnackbar('Customer Update Failed!', { variant })
        }
      }
    }

    //update a user
    if (type === "user") {

      let userTypeValueId = userType[0].id;
      //data to update a user 
      const updateUser = {
        id: id, fname: Fname, lname: surname, address, tpNo: telephone, mobileNo: mobile,
        faxNo: fax, email, dob: dob ? newDob : dob,
        vatNo: vatCode, status: statusValue, idCountry: countryId,
        idUserType: userTypeValueId, companyName
      }

      try {
        const response = await axios.put(UPDATE_USER, updateUser);
        const status = response.data.status
        if (status) {
          navigate('/partners', { state: "update success" });
          variant = 'success'
          enqueueSnackbar('User Update Successful!', { variant })
        } else {
          variant = 'error'
          enqueueSnackbar('User Update Failed!', { variant })
        }
      } catch (err) {
        setSendRequest(false)
        variant = 'error'
        if (!err.response) {
          enqueueSnackbar(ErrorMsgs[0].noResponse, { variant })
        } else if (err.response !== 403) {
          enqueueSnackbar('User Update Failed!', { variant })
        }
      }
    }
  }

  const handleKeyDown = (event) => {
    // check if the key pressed is not a number or a control key
    if (!((event.keyCode >= 48 && event.keyCode <= 57)
      || (event.keyCode >= 96 && event.keyCode <= 105)
      || event.keyCode === 8 || event.keyCode === 9
      || event.keyCode === 37 || event.keyCode === 39
      || (event.ctrlKey && (event.keyCode === 67 || event.keyCode === 86)) // allow Ctrl+C and Ctrl+V
      || (event.metaKey && (event.keyCode === 67 || event.keyCode === 86)) // allow Cmd+C and Cmd+V on macOS
    )) {
      event.preventDefault();
    }
  };



  const passwordEdit = async (e) => {
    e.preventDefault();

    const reset = { idUser: id, password }
    try {
      const response = await axios.post(RESET_PASSWORD, reset);
      const status = response.data.status;
      if (status) {
        navigate('/partners');
        variant = 'success'
        enqueueSnackbar('Password Reset Successful!', { variant })
      } else {
        variant = 'error'
        enqueueSnackbar('Password Reset Failed!', { variant })
      }
    } catch (err) {
      variant = 'error'
      if (!err.response) {
        enqueueSnackbar(ErrorMsgs[0].noResponse, { variant })
      } else if (err.response !== 403) {
        enqueueSnackbar('Password Reset Failed!', { variant })
      }
    }
  }

  return (
    <MDBContainer fluid className='container-style reg-main-con min-height'>
      <MDBRow className='d-flex justify-content-center align-items-center h-auto'>
        <MDBCol col='12' className='m-5 p-5'>
          <SnackbarAlerts
            open={open}
            setOpen={setOpen}
            msg={errMsg}
            severity={severity}
          />
          <MDBCard className='card-registration card-registration-2' style={{ borderRadius: '15px' }}>
            <MDBCardBody style={{ paddingLeft: '0 !important', paddingRight: '0 !important' }}>

              <Form >
                <MDBRow>
                  <MDBCol md='6' className='bg-indigo p-5'>
                    <h3 className="fw-normal mb-5 text-white" style={{ color: '#4835d4' }}>User Information</h3>
                    <MDBRow>
                      <MDBCol md='12'>
                        <Form.Group className='mb-3' >
                          <Form.Label className='white-lable' size='sm'>Partnership Type</Form.Label>
                          <span className="mandatory"> *</span>
                          <Typeahead
                            className={type === 'customer' ? 'hide-row' : ''}
                            id="partnershipid"
                            ref={userTypeRef}
                            selected={userType}
                            labelKey="type"
                            onChange={(selected) => setUserType(selected)}
                            options={allowedTypes}
                            placeholder="Partnership type..."
                            value={userType}
                            onFocus={() => setUserTypeFocus(true)}
                            onBlur={validateUserType}
                            style={type === 'user' ? { display: 'flex' } : ''}
                          ><TypeAheadClear select={userType} setSelect={setUserType} /></Typeahead>
                          <p className={userType.length === 0 && userTYpeFocus ? "validate" : "offscreen"}>
                            SELECT<br />
                          </p>
                          <Form.Control
                            className={type === 'user' ? 'hide-row' : ''}
                            type='text'
                            id='vatCodeid'
                            onChange={(e) => setCustomer(e.target.value)}
                            required
                            value={customer}
                            disabled
                          />
                        </Form.Group>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md='12'>
                        <Form.Group className='mb-3' >
                          <Form.Label className='white-lable' size='sm'>Company Name</Form.Label>
                          <span className="mandatory"> *</span>
                          <Form.Control
                            type='text'
                            id='companyId'
                            onChange={(e) => setCompanyName(e.target.value)}
                            value={companyName}
                            aria-invalid={validFname ? "false" : "true"}
                            aria-describedby="companyIdNote"
                            onFocus={() => setCompanyFocus(true)}
                          /* onBlur={() => setCompanyFocus(false)} */
                          />
                          <p id="fnameidnote" className={companyFocus && isEmpty(companyName) ? "validate" : "offscreen"}>
                            Company name can't be empty. <br />
                          </p>
                        </Form.Group>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md='6'>
                        <Form.Group className='mb-3' >
                          <Form.Label className='white-lable' size='sm'>First Name</Form.Label>
                          <span className="mandatory"> *</span>
                          <Form.Control
                            type='text'
                            id='nameid'
                            onChange={(e) => setFname(e.target.value)}
                            required
                            value={Fname}
                            aria-invalid={validFname ? "false" : "true"}
                            aria-describedby="fnameidnote"
                            onFocus={() => setFNameFocus(true)}/*
                          onBlur={() => setFNameFocus(false)} */
                          />
                          <p id="fnameidnote" className={FnameFocus && !validFname ? "validate" : "offscreen"}>
                            First name can't be empty. <br />
                          </p>
                        </Form.Group>
                      </MDBCol>
                      <MDBCol md='6'>
                        <Form.Group className='mb-3' >
                          <Form.Label className='white-lable' size='sm'>Last Name</Form.Label>
                          <Form.Control
                            type='text'
                            id='lnameid'
                            onChange={(e) => setSurname(e.target.value)}
                            required
                            value={surname}
                          />
                        </Form.Group>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md='6'>
                        <Form.Group className='mb-3' >
                          <Form.Label className='white-lable' size='sm'>VAT/GST Code</Form.Label>
                          <Form.Control
                            type='text'
                            id='vatCodeid'
                            onChange={(e) => setVatcode(e.target.value)}
                            required
                            value={vatCode}
                            aria-invalid={ValidVatCode ? "false" : "true"}
                            aria-describedby="vatidnote"
                            onFocus={() => setVatCodeFocus(true)}
                            onBlur={() => setVatCodeFocus(false)}
                          />
                        </Form.Group>
                      </MDBCol>
                      <MDBCol md='6'>
                        <Form.Group className='mb-3' >
                          <Form.Label className='white-lable' size='sm'>Date of Birth</Form.Label>
                          <Form.Control
                            type='date'
                            id='dobid'
                            onChange={(e) => {
                              const formattedDate = formatDate(e.target.value);
                              setDob(formattedDate);
                              //console.log('Updated dob:', formattedDate);
                            }}
                            value={dob || newDob}
                          /* max={fifteenYearsAgo.toISOString().substr(0, 10)} */
                          // maxLength={10}
                          />
                        </Form.Group>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow >
                      <MDBCol md='6' className={type === 'user' ? 'hide' : ''}>
                        <Form.Group className='mb-3 white-lable' >
                          <MDBRow className='mb-2' >
                            <Form.Label size='sm'>Gender</Form.Label>
                          </MDBRow>
                          <MDBRow className='me-2'>
                            <MDBCol md='6'>
                              <MDBRadio name='gender' id='inlineRadio1' value='Male' label='Male' onChange={(e) => setGender(e.target.value)} />
                            </MDBCol>
                            <MDBCol md='6'>
                              <MDBRadio name='gender' id='inlineRadio2' value='Female' label='Female' onChange={(e) => setGender(e.target.value)} />
                            </MDBCol>
                          </MDBRow>
                        </Form.Group>
                      </MDBCol>
                      <MDBCol md='6' >
                        <Form.Group className={type === 'user' ? 'hide' : 'mb-3'}>
                          <Form.Label className='white-lable' size='sm'>Industry</Form.Label>
                          <span className="mandatory"> *</span>
                          <Typeahead
                            id="industryId"
                            ref={industryRef}
                            selected={industry}
                            labelKey="name"
                            onChange={(selected) => setIndustry(selected)}
                            options={industryOptions}
                            placeholder="Industry..."
                            value={industry}
                            onFocus={() => setIndustryFocus(true)}
                            onBlur={validateIndustry}
                            style={{ display: 'flex' }}
                          ><TypeAheadClear select={industry} setSelect={setIndustry} /></Typeahead>
                          <p className={industry.length === 0 && industryFocus ? "validate" : "offscreen"}>
                            SELECT<br />
                          </p>
                        </Form.Group>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md='6' className={id !== 0 ? 'mb-3' : 'hide-row'}>
                        <Form.Group  >
                          <Form.Label className='white-lable' size='sm'>Status</Form.Label>
                          <span className="mandatory"> *</span>
                          <Typeahead
                            id="statusId"
                            ref={statusRef}
                            selected={statuz}
                            labelKey="name"
                            onChange={(selected) => setStatuz(selected)}
                            options={statusOption}
                            placeholder="Status..."
                            value={statuz}
                            style={{ display: 'flex' }}
                            onFocus={() => setStatusFocus(true)}
                          ><TypeAheadClear select={statuz} setSelect={setStatuz} /></Typeahead>
                          <p className={!validStatus ? "validate" : "offscreen"}>
                            SELECT<br />
                          </p>
                        </Form.Group>
                      </MDBCol>
                    </MDBRow>
                    <p className='user-instructions p-1 mt-3'>
                      Email address is used to identify users. Therefore use an accurate email address for registration.
                    </p>
                    {resetPwAccess && (
                      <fieldset
                        className={id !== 0 && type === 'user' ? 'fieldset-white' : 'hide-row'}
                        disabled={!resetPwAccess}
                      >
                        <legend className='small'>
                          Password Reset
                          <span className='mx-1'>
                            <BiEdit className='pw-icon' onClick={() => setEditPw(!editPw)} />
                          </span>
                        </legend>
                        <fieldset disabled={!editPw} className='fieldset-invisible'>
                          <MDBRow>
                            <MDBCol md='12'>
                              <InputGroup className="mb-3">
                                <Form.Control
                                  type='text'
                                  id='passwordId'
                                  onChange={(e) => setPassword(e.target.value)}
                                  value={password}
                                  onFocus={() => setPwdFocus(true)}
                                  onBlur={() => setPwdFocus(false)}
                                />
                                <Button onClick={passwordEdit} disabled={!validPwd}>Reset</Button>
                              </InputGroup>
                              <p className={pwdFocus && !validPwd ? "validate" : "hide"}>
                                Password should contain 6 to 10 characters
                              </p>
                            </MDBCol>
                          </MDBRow>
                        </fieldset>
                      </fieldset>
                    )}
                                                              
                  </MDBCol>
                  <MDBCol md='6' className='p-5 bg-white'>
                    <h3 className="fw-normal mb-3" style={{ color: '#4fc3f7' }}>Contact Information</h3>
                    <fieldset>
                      <legend className='small'>Address</legend>
                      <MDBRow className='p-0 m-0'>
                        <MDBCol md='12'>
                          <Form.Group className='mb-3' >
                            <Form.Label size='sm'>Address 1</Form.Label>
                            <Form.Control
                              type='text'
                              id='numberid'
                              onChange={(e) => setNumber(e.target.value)}
                              value={fullAddress || number}
                            />
                          </Form.Group>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className='p-0 m-0'>
                        <MDBCol md='12'>
                          <Form.Group className='mb-3' >
                            <Form.Label size='sm'>Address 2</Form.Label>
                            <Form.Control
                              type='text'
                              id='streetid'
                              onChange={(e) => setStreet(e.target.value)}
                              value={street}
                            />
                          </Form.Group>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className='p-0 m-0'>
                        <MDBCol md='7'>
                          <Form.Group className='mb-3' >
                            <Form.Label size='sm'>City</Form.Label>
                            <span className="mandatory"> *</span>
                            <Form.Control
                              type='text'
                              id='cityid'
                              onChange={(e) => setCity(e.target.value)}
                              value={city}
                              aria-describedby="cityIdNote"
                              onFocus={() => setCityFocus(true)}
                            /* onBlur={() => setCityFocus(false)} */
                            />
                            <p id="cityIdNote" className={cityFocus && isEmpty(city) ? "validate" : "offscreen"}>
                              City can't be empty. <br />
                            </p>
                          </Form.Group>
                        </MDBCol>
                        <MDBCol md='5'>
                          <Form.Group className='mb-3' >
                            <Form.Label size='sm'>Postal Code</Form.Label>
                            <Form.Control
                              type='text'
                              id='postalcodeid'
                              onChange={(e) => setPostalcode(e.target.value)}
                              required
                              value={postalcode}/*
                          aria-invalid={validpostalcode ? "false" : "true"} */
                              aria-describedby="postalidnote"
                              onFocus={() => setPostalcodeFocus(true)}/*
                          onBlur={() => setPostalcodeFocus(false)} */
                            />
                          </Form.Group>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className='p-0 m-0'>
                        <MDBCol md='6'>
                          <Form.Group className='mb-3' >
                            <Form.Label size='sm'>Province</Form.Label>
                            <Form.Control
                              type='text'
                              id='postalcode'
                              onChange={(e) => setProvince(e.target.value)}
                              required
                              value={province}
                            />
                          </Form.Group>
                        </MDBCol>
                        <MDBCol md='6'>
                          <Form.Group className='mb-3' >
                            <Form.Label size='sm'>Country</Form.Label>
                            <span className="mandatory"> *</span>
                            <Typeahead
                              id="countryid"
                              ref={countryRef}
                              selected={country}
                              labelKey="name"
                              onChange={(selected) => setCountry(selected)}
                              options={countryOptions}
                              placeholder="Country..."
                              onFocus={() => setCountryFocus(true)}
                              onBlur={validateCountry}
                              style={{ display: 'flex' }}
                            ><TypeAheadClear select={country} setSelect={setCountry} /></Typeahead>
                            <p className={country.length === 0 && countryFocus ? "validate" : "offscreen"}>
                              SELECT<br />
                            </p>
                          </Form.Group>
                        </MDBCol>
                      </MDBRow>
                    </fieldset>
                    <MDBRow className='mt-2'>
                      <MDBCol md='6'>
                        <Form.Group className='mb-3' >
                          <Form.Label size='sm'>Telephone</Form.Label>
                          <Form.Control
                            type='text'
                            id='telephoneid'
                            onChange={(e) => setTelephone(e.target.value)}
                            onKeyDown={handleKeyDown}
                            required
                            value={telephone}
                            aria-invalid={validTelephone ? "false" : "true"}
                            aria-describedby="teleidnote"
                            onFocus={() => setTelephoneFocus(true)}/*
                          onBlur={() => setTelephoneFocus(false)} */
                          />
                          <p id="teleidnote" className={telephoneFocus && !validTelephone && !isEmpty(telephone) ? "validate" : "offscreen"}>
                            Enter a valid telephone number. <br />
                          </p>
                        </Form.Group>
                      </MDBCol>
                      <MDBCol md='6'>
                        <Form.Group className='mb-3' >
                          <Form.Label size='sm'>Mobile</Form.Label>
                          <span className="mandatory"> *</span>
                          <Form.Control
                            type='text'
                            id='MobileId'
                            onChange={(e) => setMobile(e.target.value)}
                            onKeyDown={handleKeyDown}
                            required
                            value={mobile}
                            aria-invalid={validMobile ? "false" : "true"}
                            aria-describedby="mobileidnote"
                            onFocus={() => setMobileFocus(true)}
                          /* onBlur={() => setMobileFocus(false)} */
                          />
                          <p id="mobileidnote" className={mobileFocus && !validMobile ? "validate" : "offscreen"}>
                            Enter a valid mobile number.<br />
                          </p>
                        </Form.Group>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>{/*
                  <MDBCol md='6' className={type === 'customer' ? 'hide-row' : ''}>
                      <Form.Group className='mb-3' >
                        <Form.Label size='sm'>Fax</Form.Label>
                        <Form.Control
                          type='text'
                          id='faxid'
                          onChange={(e) => setFax(e.target.value)}
                          onKeyDown={handleKeyDown}
                          required
                          value={fax}
                          aria-invalid={validFax ? "false" : "true"}
                          aria-describedby="faxidnote"
                          onFocus={() => setfaxFocus(true)}
                          onBlur={() => setfaxFocus(false)}
                        />
                        <p id="faxidnote" className={faxFocus && !validFax ? "validate" : "offscreen"}>
                            Enter a valid fax.<br />
                        </p>
                      </Form.Group>
                    </MDBCol> */}
                      <MDBCol md='12'>
                        <Form.Group className='mb-3' >
                          <Form.Label size='sm'>Email</Form.Label>
                          <span className="mandatory"> *</span>
                          <Form.Control
                            type='email'
                            id='emailid'
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            value={email}
                            aria-invalid={validEmail ? "false" : "true"}
                            aria-describedby="emailidnote"
                            onFocus={() => { setEmailFocus(true) }}/*
                          onBlur={() => {setEmailFocus(false)}} */
                          />
                          <p id="emailidnote" className={!validEmail && emailFocus ? "validate" : "offscreen"}>
                            Enter a valid email address.<br />
                          </p>
                          <p className={usedEmail ? "validate" : "offscreen"}>
                            This email address is already used.<br />
                          </p>
                        </Form.Group>
                      </MDBCol>
                    </MDBRow>
                    <Button
                      className={id !== 0 ? 'hide-row' : ''}
                      type='submit'
                      onClick={handleSubmit}
                      disabled={!validUserType || !validCountry || isEmpty(companyName) || !validFname || isEmpty(city)
                        || !validMobile || !validEmail || sentRequest || !validIndustry || usedEmail}
                      color='primary'
                    >Register</Button>
                    <Button
                      className={id === 0 ? 'hide-row' : ''}
                      type='submit'
                      onClick={handleUpdate}
                      disabled={!validUserType || !validCountry || isEmpty(companyName) || !validFname || isEmpty(city)
                        || !validMobile || !validEmail || sentRequest || !validIndustry || !validStatus || usedEmail}
                      color='primary'
                    >Update</Button>
                  </MDBCol>
                </MDBRow>
              </Form>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
    /*  )}
     </> */
  );
}

export default Reg;