import './Table.css';
import UserTable from './UserTable';
import { MDBRow, MDBCol, MDBContainer, MDBCard, MDBCardBody}from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as BR from 'react-bootstrap';
import SnackbarAlerts from '../SnackbarAlerts';
import DistributorFullStockReport from './DistributorFullStockReport';

function DistributorFullReport() {
  
  const location = useLocation()
  const [open, setOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [severity, setSeverity] = useState('error');
  const [tabpanelHeight, setTabpanelHeight] = useState('auto');
  const state = location.state;

  useEffect(() => {
    document.title = 'Distributor Stock Report';
  }, []);

  useEffect(() => {
    const tabpanel = document.getElementById('simple-tabpanel-0');
    const contentHeight = tabpanel.offsetHeight;
    setTabpanelHeight(contentHeight);
  }, []);
  
  return (
    <BR.Container fluid className='Bcontainer-style min-height'>
      <BR.Row className='d-flex justify-content-center align-items-center h-auto' >
        <BR.Col col='12' className='my-5' >          
          <BR.Card className='card-table'>
            <BR.CardBody className='p-0'>
              <BR.Row className='bg-white pt-3 p-2 h-80' >
                <BR.Col md='12'>
                  <SnackbarAlerts open={open} setOpen={setOpen} msg={alertMsg} severity={severity}/>
                  <DistributorFullStockReport />
                </BR.Col>
              </BR.Row>
            </BR.CardBody>
          </BR.Card>
        </BR.Col>
      </BR.Row>
    </BR.Container>
    

  );
}

export default DistributorFullReport;
