import axios from "../../api/axios";
import React, { useEffect, useMemo, useState, useContext, useRef } from "react";
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { enqueueSnackbar } from 'notistack';
import { Variables } from '../../Data/Data';
import DataContext from '../../context/DataContext';
import AuthProvider from '../../context/AuthProvider';
import KeyGenData from '../../context/KeyGenData';
import { TablesFilterSeller, FilterByUsers } from "../../Data/Data"

import * as RB from 'react-bootstrap';
import * as moment from 'moment';
import { Autocomplete, TextField } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import IconButton from '@mui/material/IconButton';
import PrintIcon from '@mui/icons-material/Print';
import { ErrorMsgs } from '../../Data/Data';
import "./Distributor.css";

const USER_URL = process.env.REACT_APP_API_SELLER_FIND_ALL_SELLERS;
const USER_KEYS = process.env.REACT_APP_API_USER_RELATED_KEY_DETAILS;

const DistributorFullStockReport = () => {
  const maxSelectableDate = moment().endOf('day');
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [sellerId, setSellerId] = useState(null);
  const [sellerDetails, setSellerDetails] = useState({});
  const [availableUsers, setAvailableUsers] = useState([]);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [filterText, setFilterText] = useState('');
  const [openSearch, setOpenSearch] = useState(false);
  const [searchClicked, setSearchClicked] = useState(false); // New state to track if search was clicked
  const autocompleteRef = useRef(null);
  const [flattenedData, setFlattenedData] = useState([]);
  const [searchedData, setSearchedData] = useState([]);
  const [showTotalRow, setShowTotalRow] = useState(true);
  const [type, setType] = useState(2);
  const [dataReady, setDataReady] = useState(false); 
  const [fullname, setFullname] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  const { fullName} = useContext(DataContext)
  const { DistributorFullportsPrint} = useContext(AuthProvider)

  
  const customStyles = {
    rows: {
      style: {
        minHeight: '100px',
      },
    },
    headCells: {
      style: {
        borderStyle: 'solid',
        borderColor: '#40c4ff',
        borderWidth: '1px',
        borderCollapse: 'collapse',
        fontWeight: 'bold',
        padding: '5px',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#b2ebf2',
        height: '60px',
      },
    },
    cells: {
      style: {
        overFlowWrap: 'break-word',
        borderStyle: 'solid',
        borderColor: '#40c4ff',
        borderWidth: '1px',
        borderCollapse: 'collapse',
        padding: '5px',
        alignItems: 'center',
        justifyContent: 'center',
        height: '60px',
        width: '152px',
      },
    },
  };

  const tableStyle = {
    headCells: customStyles.headCells.style,
    rows: customStyles.rows.style,
    cells: customStyles.cells.style,
  };

  const fetchUsers = async (type, filter) => {
    try {
      const response = await axios.get(`${USER_URL}/${type}`, { params: { search: filter } });
      const { status, result, msg } = response.data;
      if (status) {
        if (result) {
          setUsers(result);
          setAvailableUsers(result.map(user => `${user.fname} ${user.lname}`));

        } else {
          setUsers([]);
        }
      } else {
        enqueueSnackbar(msg, { variant: 'error' });
      }
    } catch (err) {
      enqueueSnackbar(ErrorMsgs[0].fetchError, { variant: 'error' });
    }
  };

  const fetchAllUsers = async (type) => {
    try {
      const response = await axios.get(USER_URL + `/${type}`);
      const status = response.data.status;
      const result = response.data.result;
      const msg = response.data.msg;

      if (status) {
        if (result && result.length > 0) {
          setUsers(result);
          return result.map(user => user.id);
        } else {
          setUsers([]);
          return [];
        }
      } else {
        setUsers([]);
        enqueueSnackbar(msg, { variant: 'error' });
      }
    } catch (err) {
      if (!err.response) {
        enqueueSnackbar(ErrorMsgs[0].noResponse, { variant: 'error' });
      } else if (err.response !== 403) {
        enqueueSnackbar(ErrorMsgs[0].fetchError, { variant: 'error' });
      }
      return [];
    }
  };

  const fetchUserDetails = async (userId, fromDate, toDate) => {
    try {
      const formattedFromDate = moment(fromDate).format('YYYY-MM-DD');
      const formattedToDate = moment(toDate).format('YYYY-MM-DD');
      const response = await axios.get(`${USER_KEYS}${userId}/${formattedFromDate}/${formattedToDate}`);
      const { status, result, msg } = response.data;
      //console.log('Data', response);
      if (status) {
        setSellerDetails(result); 
        //console.log('seller data' , sellerDetails)
        const data = searchData(sellerDetails)
        setSearchedData(data)
        return result;
      } else {
        throw new Error(msg);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const fetchDistributorDetails = async (userIds, fromDate, toDate) => {
    try {
      const formattedFromDate = moment(fromDate).format('YYYY-MM-DD');
      const formattedToDate = moment(toDate).format('YYYY-MM-DD');
      const userDetailsPromises = userIds.map(userId => fetchUserDetails(userId, formattedFromDate, formattedToDate));
      const userDetailsArray = await Promise.all(userDetailsPromises);
      const validUserDetails = userDetailsArray.filter(details => details !== null);
      const data = flattenData(validUserDetails);
      setFlattenedData(data);
      return data;


    } catch (error) {
      setFlattenedData([]);


      enqueueSnackbar('Error fetching reseller details', { variant: 'error' });
    }
  };

  useEffect(() => {
    if (filterText) {
      fetchUsers(0, filterText);
      fetchAllUsers(0);
    } else {
      setUsers([]);
      setSellerId(null);
    }
  }, [filterText]);

  /*  const flattenData = (data) => {
     const flatten = (user, level = 0) => {
       const { userName, keyStock = {}, users = [] } = user;
       const { avPrimKeys = 0, avTempKeys = 0, crPrimKeys = 0, crTempKeys = 0, acPrimKeys = 0, acTempKeys = 0 } = keyStock;
   
       let result = [
         {
           userName,
           avPrimKeys,
           avTempKeys,
           crPrimKeys,
           crTempKeys,
           acPrimKeys,
           acTempKeys,
           level,
         },
       ];
   
       if (users.length > 0) {
         users.forEach((reseller) => {
           result = result.concat(flatten(reseller, level + 1));
         });
       }
   
       return result;
     };
   
     return flatten(data);
   };
   
  */

  const flattenData = (data) => {
    const flatten = (user, level = 0, parentDistributor = null, parentCity = '') => {
      const { userName, keyStock = {}, users = [] , city = ''} = user;
      const { avPrimKeys = 0,  avPerpetualKeys = 0, avTempKeys = 0, crPrimKeys = 0, crPerpetualKeys = 0, crTempKeys = 0, acPrimKeys = 0, acTempKeys = 0 } = keyStock;
      let resellerCount = 0;

      const currentCity = city || parentCity;

      // Only include keystock details for Resellers (level > 1)
      const userDetails = {
        superDistributor: parentDistributor || userName,
        distributorName: userName,
        resellerName: level > 0 ? userName : '',
        city: level > 0 ? currentCity : '',
        avPrimKeys: level > 0 ? avPrimKeys : undefined,
        avPerpetualKeys: level > 0 ? avPerpetualKeys : undefined,
        avTempKeys: level > 0 ? avTempKeys : undefined,
        crPrimKeys: level > 0 ? crPrimKeys : undefined,
        crPerpetualKeys: level > 0 ? crPerpetualKeys : undefined,
        crTempKeys: level > 0 ? crTempKeys : undefined,
        acPrimKeys: level > 0 ? acPrimKeys : undefined,
        acTempKeys: level > 0 ? acTempKeys : undefined,
        level,
        resellerCount: users.length,
      };

      let result = [userDetails];

      if (users.length > 0) {
        users.forEach((reseller) => {
          const flattenedReseller = flatten(reseller, level + 1, parentDistributor || userName);
          resellerCount += flattenedReseller.length;
          result = result.concat(flattenedReseller);
        });
      }

      if (level === 0) {
        result[0].resellerCount = resellerCount + 1;
      }

      return result;
    };

    return data.flatMap(user => flatten(user));
  };


  const searchData = (data) => {
    const flatten = (user, level = 0, parentDistributor = null) => {
      const { userName, keyStock = {}, users = [], city = '' } = user;
      const { avPrimKeys = 0, avPerpetualKeys = 0, avTempKeys = 0, crPrimKeys = 0, crPerpetualKeys = 0, crTempKeys = 0, acPrimKeys = 0, acTempKeys = 0 } = keyStock;
      let resellerCount = 0;
      
      // Only include keystock details for Resellers (level > 1)
      const userDetails = {
        superDistributor: parentDistributor || userName,
        distributorName: userName,
        resellerName: level > 0 ? userName : '',
        city: level > 0 ? city : '',
        avPrimKeys: level > 0 ? avPrimKeys : undefined,
        avPerpetualKeys: level > 0 ? avPerpetualKeys : undefined,
        avTempKeys: level > 0 ? avTempKeys : undefined,
        crPrimKeys: level > 0 ? crPrimKeys : undefined,
        crPerpetualKeys: level > 0 ? crPerpetualKeys : undefined,
        crTempKeys: level > 0 ? crTempKeys : undefined,
        acPrimKeys: level > 0 ? acPrimKeys : undefined,
        acTempKeys: level > 0 ? acTempKeys : undefined,
        level,
        resellerCount: users.length,
      };

      let result = [userDetails];

      if (users.length > 0) {
        users.forEach((reseller) => {
          const flattenedReseller = flatten(reseller, level + 1, parentDistributor || userName);
          resellerCount += flattenedReseller.length;
          result = result.concat(flattenedReseller);
        });
      }

      if (level === 0) {
        result[0].resellerCount = resellerCount + 1;
      }

      return result;

    };

    return flatten(data);
  };

useEffect(() => {

}, [sellerId]);



  useEffect(() => {
    const loadDistributorsAndDetails = async () => {
      const userIds = await fetchAllUsers(0);
      //console.log('--- useEffect Triggered ---');
      //console.log('Type:', type);
      //console.log('From Date:', fromDate);
      //console.log('To Date:', toDate);
      //console.log('User IDs:', userIds);

      if (userIds.length > 0) {
        await fetchDistributorDetails(userIds, fromDate, toDate);
        //console.log('Fetching distributor details...');
        setDataReady(true);
      } else {
        enqueueSnackbar('No users available for fetching distributor details', { variant: 'warning' });
    }
    };

    //console.log('Loading distributors and details...');
    loadDistributorsAndDetails();
  }, [type, fromDate, toDate]);

 
 

  const handleSearch = async () => {
    setSellerDetails({});
    if (sellerId) {
      setSearchClicked(true);

      try {
        const sellerDetails = await fetchUserDetails(sellerId, fromDate, toDate);
        const data = searchData(sellerDetails);
        setSearchedData(data);
        setSellerDetails(sellerDetails);
        setOpenSearch(true);
      } catch (error) {
        setSellerId(null);
        setSearchedData([]);
        setSellerDetails({});
        setOpenSearch(false);
        enqueueSnackbar('No sellers found for this distributor', { variant: 'error' });
      }
    } else {
      setSellerId(null);
      setSearchedData([]);
      setSellerDetails({});
      setOpenSearch(false);
      enqueueSnackbar('Please select a user and specify dates', { variant: 'error' });
    }
  };

  const handleResetDates = () => {
    setFromDate(new Date());
    setToDate(new Date());
    setSellerDetails({});
    setSellerId(null);
    setSearchClicked(false); // Reset the search clicked state
    
    setShowTotalRow(false);
  };

  const calculateTotals = (data) => {

    let totalAvPrimKeys = 0;
    let totalCrTempKeys = 0;
    let totalAcTempKeys = 0;
    let totalCrPrimKeys = 0;
    let totalCrPerpetual = 0;
    let totalAvPerpetual = 0;


    if (data && Array.isArray(data)) {

      data.forEach((row) => {
        if (row.avPrimKeys) totalAvPrimKeys += row.avPrimKeys;
        if (row.avPerpetualKeys) totalAvPerpetual += row.avPerpetualKeys;
        if (row.crTempKeys) totalCrTempKeys += row.crTempKeys;
        if (row.acTempKeys) totalAcTempKeys += row.acTempKeys;
        if (row.crPrimKeys) totalCrPrimKeys += row.crPrimKeys;
        if (row.crPerpetualKeys) totalCrPerpetual += row.crPerpetualKeys;
      });
    } else {
      console.error('Data is not defined or is not an array:', data);

      return { totalAvPrimKeys, totalAvPerpetual, totalCrPerpetual, totalCrTempKeys, totalAcTempKeys, totalCrPrimKeys };
    }

    return { totalAvPrimKeys, totalAvPerpetual, totalCrPerpetual, totalCrTempKeys, totalAcTempKeys, totalCrPrimKeys };
  };


  // Calculate totals
  const totals = calculateTotals(flattenedData);
  const searchTotals = calculateTotals(searchedData);


 
  useEffect(() => {
    // This effect runs after the first effect, so filteredItems should be updated
   // console.log('Seller ID:', sellerId);
    loadDataForPrint();
    loadDataForSearchPrint();
  }, [sellerId]);

  const loadDataForPrint = async () => {
    try {
      let dataToUse = [];
      
        const userIds = await fetchAllUsers(0); // Adjust the type if needed
        console.log('User IDs:', userIds);
  
        if (userIds.length > 0) {
          dataToUse = await fetchDistributorDetails(userIds, fromDate, toDate);
         // console.log('Fetching distributor details...');
        }
      
      return dataToUse;
    } catch (error) {
      console.error('Error loading data for print:', error);
      enqueueSnackbar('Error loading data for print', { variant: 'error' });
      return [];
    }
  };
  

  const loadDataForSearchPrint = async () => {
    try {
      let dataToUse = [];

      if(sellerId && filterText !== ''){
        const data = await fetchUserDetails(sellerId, fromDate, toDate);
        dataToUse = searchData(data); // Assuming searchData processes the fetched data correctly
        //console.log('Fetching search data...');
      }
      
      return dataToUse;
    } catch (error) {
      console.error('Error loading data for print:', error);
      enqueueSnackbar('Error loading data for print', { variant: 'error' });
      return [];
    }
  };
  


  //const dataToUse = (searchClicked && Array.isArray(searchedData) && searchedData.length > 0) ? searchedData : flattenedData;

  
  const handlePrint = async () => {

    let dataToUse = [];

    if ( sellerId && Array.isArray(searchedData) && searchedData.length > 0) {
      //console.log("Using searchedData:", searchedData);
      dataToUse = await loadDataForSearchPrint();
      
    } else {
     // console.log("Using flattenedData:", flattenedData);
      dataToUse = await loadDataForPrint();
      
    }
  

    if (!dataToUse) {
      enqueueSnackbar('No data available for printing', { variant: 'error' });
      return;
    }
  
    //console.log("print", dataToUse);
 
    // Format dates to YYYY-MM-DD
    const formatDateString = (dateStr) => {
      const date = new Date(dateStr);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };
  
    const formattedFromDate = fromDate ? formatDateString(fromDate) : 'N/A';
    const formattedToDate = toDate ? formatDateString(toDate) : 'N/A';
   
    //dataToUse = (Array.isArray(searchedData) && searchedData.length > 0) ? searchedData : flattenedData;

    // Open a new window for printing
    const printWindow = window.open('', '_blank', 'width=1000,height=800');
    if (printWindow) {
      // Determine which data set to use
      
      const rowSpans = dataToUse.reduce((acc, seller) => {
        const superDistributor = fullName.result.fname + " " + fullName.result.lname;
        const distributorName = seller.superDistributor;

        if (!acc.superDistributors[superDistributor]) {
            acc.superDistributors[superDistributor] = 0;
        }
        if (!acc.distributors[distributorName]) {
            acc.distributors[distributorName] = 0;
        }

        acc.superDistributors[superDistributor] += 1;
        acc.distributors[distributorName] += 1;

        return acc;
    }, { superDistributors: {}, distributors: {} });

    const printedSuperDistributors = {};
    const printedDistributors = {};

    let superDistributorTotals = {
        totalAvPrimKeys: 0,
        totalCrTempKeys: 0,
        totalAcTempKeys: 0,
        totalCrPrimKeys: 0,
        totalAvPerpetual: 0,
        totalCrPerpetual: 0
    };

    // Generate rows for each seller
    let sellerDetailsContent = '';
    let currentDistributor = null;
    let currentDistributorTotals = {
        totalAvPrimKeys: 0,
        totalCrTempKeys: 0,
        totalAcTempKeys: 0,
        totalCrPrimKeys: 0,
        totalAvPerpetual: 0,
        totalCrPerpetual: 0
    };

    dataToUse.forEach((seller, index) => {
        const superDistributor = fullName.result.fname + " " + fullName.result.lname;
        const distributorName = seller.superDistributor;

        const totals = {
            totalAvPrimKeys: seller.avPrimKeys || 0,
            totalAvPerpetual: seller.avPerpetualKeys || 0,
            totalCrTempKeys: seller.crTempKeys || 0,
            totalAcTempKeys: seller.acTempKeys || 0,
            totalCrPrimKeys: seller.crPrimKeys || 0,
            totalCrPerpetual: seller.crPerpetualKeys || 0
        };

        superDistributorTotals.totalAvPrimKeys += totals.totalAvPrimKeys;
        superDistributorTotals.totalAvPerpetual += totals.totalAvPerpetual;
        superDistributorTotals.totalCrTempKeys += totals.totalCrTempKeys;
        superDistributorTotals.totalAcTempKeys += totals.totalAcTempKeys;
        superDistributorTotals.totalCrPrimKeys += totals.totalCrPrimKeys;
        superDistributorTotals.totalCrPerpetual += totals.totalCrPerpetual;

        currentDistributorTotals.totalAvPrimKeys += totals.totalAvPrimKeys;
        currentDistributorTotals.totalAvPerpetual += totals.totalAvPerpetual;
        currentDistributorTotals.totalCrTempKeys += totals.totalCrTempKeys;
        currentDistributorTotals.totalAcTempKeys += totals.totalAcTempKeys;
        currentDistributorTotals.totalCrPrimKeys += totals.totalCrPrimKeys;
        currentDistributorTotals.totalCrPerpetual += totals.totalCrPerpetual;

        if (currentDistributor !== distributorName) {
            // Output subtotal for the previous distributor if it exists
            if (currentDistributor) {
                sellerDetailsContent += `
                    <tr style="font-weight: bold;">
                        <td colspan="4" style="text-align: right;">(Distributor SubTotal)</td>
                        <td>${currentDistributorTotals.totalAvPrimKeys}</td>
                        <td>${currentDistributorTotals.totalAvPerpetual}</td>
                        <td>${currentDistributorTotals.totalCrTempKeys}</td>
                        <td>${currentDistributorTotals.totalAcTempKeys}</td>
                        <td>${currentDistributorTotals.totalCrPrimKeys}</td>
                        <td>${currentDistributorTotals.totalCrPerpetual}</td>
                    </tr>
                `;
            }

            // Reset totals for the new distributor
            currentDistributor = distributorName;
            currentDistributorTotals = {
                totalAvPrimKeys: totals.totalAvPrimKeys,
                totalAvPerpetual: totals.totalAvPerpetual,
                totalCrTempKeys: totals.totalCrTempKeys,
                totalAcTempKeys: totals.totalAcTempKeys,
                totalCrPrimKeys: totals.totalCrPrimKeys,
                totalCrPerpetual: totals.totalCrPerpetual
            };

            // Add row for the current seller
            sellerDetailsContent += `
                <tr>
                    <td>${superDistributor}</td>
                    <td>${distributorName}</td>
                    <td>${seller.sellerNames && seller.sellerNames.length > 0 ? seller.sellerNames.join(', ') : seller.resellerName || ''}</td>
                    <td>${seller.city || ''}</td>
                    <td>${seller.avPrimKeys || 0}</td>
                    <td>${seller.avPerpetualKeys || 0}</td>
                    <td>${seller.crTempKeys || 0}</td>
                    <td>${seller.acTempKeys || 0}</td>
                    <td>${seller.crPrimKeys || 0}</td>
                    <td>${seller.crPerpetualKeys || 0}</td>
                </tr>
            `;
        } else {
            // Add row for the current seller
            sellerDetailsContent += `
                <tr>
                    <td>${superDistributor}</td>
                    <td>${distributorName}</td>
                    <td>${seller.sellerNames && seller.sellerNames.length > 0 ? seller.sellerNames.join(', ') : seller.resellerName || ''}</td>
                    <td>${seller.city || ''}</td>
                    <td>${seller.avPrimKeys || 0}</td>
                    <td>${seller.avPerpetualKeys || 0}</td>
                    <td>${seller.crTempKeys || 0}</td>
                    <td>${seller.acTempKeys || 0}</td>
                    <td>${seller.crPrimKeys || 0}</td>
                    <td>${seller.crPerpetualKeys || 0}</td>
                </tr>
            `;
        }

        // Append distributor subtotal for the last item in the list
        if (index === dataToUse.length - 1) {
            sellerDetailsContent += `
                <tr style="font-weight: bold;">
                    <td colspan="4" style="text-align: right;">(Distributor SubTotal)</td>
                    <td>${currentDistributorTotals.totalAvPrimKeys}</td>
                    <td>${currentDistributorTotals.totalAvPerpetual}</td>
                    <td>${currentDistributorTotals.totalCrTempKeys}</td>
                    <td>${currentDistributorTotals.totalAcTempKeys}</td>
                    <td>${currentDistributorTotals.totalCrPrimKeys}</td>
                    <td>${currentDistributorTotals.totalCrPerpetual}</td>
                </tr>
            `;
        }
    });

    // Generate super distributor subtotal row
    const superDistributorSubtotal = `
        <tr style="font-weight: bold;">
            <td colspan="4" style="text-align: right;">(Super Distributor SubTotal)</td>
            <td>${superDistributorTotals.totalAvPrimKeys}</td>
            <td>${superDistributorTotals.totalAvPerpetual}</td>
            <td>${superDistributorTotals.totalCrTempKeys}</td>
            <td>${superDistributorTotals.totalAcTempKeys}</td>
            <td>${superDistributorTotals.totalCrPrimKeys}</td>
            <td>${superDistributorTotals.totalCrPerpetual}</td>
        </tr>
    `;

      const firstDistributorName = dataToUse.length > 0 ? dataToUse[0].superDistributor : 'N/A';
  
      const content = `
        <!DOCTYPE html>
        <html lang="en">
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Distributor-wise Stock Activation Report</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              margin: 0;
              padding: 0;
            }
            .container {
              width: 100%;
              padding: 20px;
            }
            h1 {
              font-size: 24px;
              font-weight: bold;
              margin-bottom: 20px;
            }
            table {
              width: 100%;
              border-collapse: collapse;
            }
            th, td {
              border: 1px solid #ccc;
              padding: 8px;
              text-align: left;
            }
            th {
              background-color: #f2f2f2;
            }
          </style>
        </head>
        <body>
          <div class="container">
            <h1>Distributor-wise Stock Activation Report</h1>
            <p>Distributor Name: ${firstDistributorName || 'N/A'}</p>
            <p>Period: From <span id="fromDate">${formattedFromDate}</span> To <span id="toDate">${formattedToDate}</span></p>
            <table>
              <thead>
                <tr>
                  <th>Super Distributor</th>
                  <th>Name of Distributor</th>
                  <th>Name of Resellers</th>
                  <th>No. of Permanent Lic Available</th>
                  <th>No. of Perpetual Lic Available</th>
                  <th>No. of Trial Lic Available</th>
                  <th>No. of Trial Lic Installed</th>
                  <th>No. of Trial Lic Still active</th>
                  <th>No. of Permanent Lic Installed</th>
                  <th>No. of Perpetual Lic Installed</th>
                </tr>
              </thead>
              <tbody id="reportBody">
                ${sellerDetailsContent}
                ${superDistributorSubtotal}
                
              </tbody>
            </table>
          </div>
        </body>
        </html>
      `;
  
      // Write content to the print window
      printWindow.document.open();
      printWindow.document.write(content);
      printWindow.document.close();
      // Trigger the printing process
      printWindow.print();
      // Close the print window after printing (optional)
      printWindow.close();
    } else {
      console.error('Failed to open print window');
    }
  };
  


  //console.log("searchedData:", searchedData);
  //console.log("flattenedData:", flattenedData);





  const subHeaderComponentMemo = useMemo(() => {
    return (
      <RB.Container fluid>
        <RB.Row className='bg-blue-s p-3 mb-2'>
          <RB.Col xl='2' lg='2' sm='2' xs='12' xxl='2'>
            <RB.Form.Select
              id='keyTypeId'
              onChange={(e) => setType(e.target.value)}
              value={type}
              disabled={isDisabled}
              style={{
                width: '100%',
                backgroundColor: 'transparent',
                border: '1px solid black',
                height: '55px',
              }}

            >
              <option disabled value=''>Filter By...</option>
              {FilterByUsers.map((options, index) => (
                <option key={index} value={options.value}>{options.select}</option>
              ))}
            </RB.Form.Select>
          </RB.Col>
          <RB.Col xl='2' lg='2' sm='2' xs='12' xxl='2'>
            <Autocomplete
              freeSolo
              id="search"
              options={availableUsers.map(seller => ({ label: seller, value: seller }))}
              getOptionLabel={option => option.label}
              onChange={(event, newValue) => {
                if (newValue) {
                  setFilterText(newValue.label);
                  const selectedSeller = users.find(user => `${user.fname} ${user.lname}` === newValue.label);
                  if (selectedSeller) {
                    setSellerId(selectedSeller.id);
                  }
                } else {
                  setFilterText('');
                  setSellerId(null);
                }
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="[Distributer Name]"
                  variant="outlined"
                  onChange={e => setFilterText(e.target.value)}
                  inputRef={autocompleteRef}
                />
              )}
            />
          </RB.Col>
          <RB.Col xl='2' lg='2' sm='2' xs='12' xxl='4'>
            <RB.Row>
              <RB.Col>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    label="From Date"
                    value={fromDate}
                    maxDate={maxSelectableDate}
                    onChange={setFromDate}
                    renderInput={params => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </RB.Col>
              <RB.Col>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    label="To Date"
                    value={toDate}
                    maxDate={toDate} // Ensuring ToDate cannot be in the future
                    onChange={setToDate}
                    renderInput={params => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </RB.Col>
            </RB.Row>
          </RB.Col>
          <RB.Col xl='2' lg='2' sm='2' xs='12' xxl='3'>
            <RB.Row>
              <RB.Col>
                <RB.Button variant="outline-danger" id="clear-button" type="button" onClick={handleResetDates} style={{ padding: '15px 20px', fontSize: '16px' }}>Reset</RB.Button>{' '}
              </RB.Col>
              <RB.Col>
                <RB.Button
                  id="search-button"
                  type="button"
                  onClick={() => {
                    if (sellerId && fromDate && toDate) {
                      handleSearch();
                    } else {
                      enqueueSnackbar('Please select a user and specify dates', { variant: 'error' });
                    }
                  }}
                  style={{ marginLeft: '-35px', padding: '15px 20px', fontSize: '16px' }}
                >
                  Search
                </RB.Button>
              </RB.Col >
              <RB.Col style={{ marginRight: '-120px' }}>
              {DistributorFullportsPrint && (
                <RB.Button id="print-button" type="button" onClick={handlePrint} style={{ width: '105px', padding: '15px 20px', fontSize: '16px' }}>
                  <PrintIcon />
                  Print
                </RB.Button>
              )}
              </RB.Col>
            </RB.Row>
          </RB.Col>
        </RB.Row>

      </RB.Container>
    );
  }, [filterText, sellerId, fromDate, toDate, showTotalRow]);

  return (
    <>

      <DataTable
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        striped
        dense
        className="custom-datatable"
      />





<RB.Container fluid>
    <RB.Row style={{ marginRight: '-28px' }}>
      <RB.Col className="col-table" style={{ marginLeft: '-10px', marginRight: '0px', height: '600px', overflow: 'hidden' }}>
        <RB.Table striped bordered hover responsive>
          <thead className="sticky-header" style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
            <tr>
              <th style={{ ...tableStyle.headCells, width: '170px' }}>Super Distributor</th>
              <th style={{ ...tableStyle.headCells, width: '140px'  }}>Name of Distributor</th>
              <th style={{ ...tableStyle.headCells, width: '160px'  }}>Reseller Name</th>
              <th style={{ ...tableStyle.headCells , width: '110px'  }}>City</th>
              <th style={{ ...tableStyle.headCells  }}>No of Lic Available</th>
              <th style={{ ...tableStyle.headCells  }}>No of Perpetual Lic Available</th>
              <th style={{ ...tableStyle.headCells  }}>No of Trial Lic Installed</th>
              <th style={{ ...tableStyle.headCells  }}>No of Trial Lic Still Active</th>
              <th style={{ ...tableStyle.headCells , width: '110px'}}>No of Permanent Lic Installed</th>
              <th style={{ ...tableStyle.headCells  }}>No of Perpetual Lic Installed</th>
            </tr>
          </thead>
        </RB.Table>
        <div style={{ height: '500px', overflowY: 'auto' }}>
          <RB.Table striped bordered hover responsive>
            <tbody>
              {(searchClicked ? searchedData : flattenedData).map((row, index) => (
                <React.Fragment key={index}>
                  <tr style={tableStyle.rows}>
                    {index === 0 || row.superDistributor !== ((searchClicked ? searchedData[index - 1]?.superDistributor : flattenedData[index - 1]?.superDistributor) || undefined) ? (
                      <>
                        <td rowSpan={(searchClicked ? searchedData.filter(r => r.superDistributor === row.superDistributor).length : flattenedData.filter(r => r.superDistributor === row.superDistributor).length) || 1} style={{ ...tableStyle.cells, width: '200px' }}>
                          {fullName.result.fname + ' ' + fullName.result.lname}
                        </td>
                        <td rowSpan={(searchClicked ? searchedData.filter(r => r.superDistributor === row.superDistributor).length : flattenedData.filter(r => r.superDistributor === row.superDistributor).length) || 1} style={{ ...tableStyle.cells, width: '200px' }}>
                          {row.distributorName}
                        </td>
                      </>
                    ) : null}
                    <td style={{ ...tableStyle.cells }}>{row.resellerName}</td>
                    <td style={tableStyle.cells}>{row.city}</td>
                    <td style={tableStyle.cells}>{row.avPrimKeys}</td>
                    <td style={tableStyle.cells}>{row.avPerpetualKeys}</td>
                    <td style={tableStyle.cells}>{row.crTempKeys}</td>
                    <td style={tableStyle.cells}>{row.acTempKeys}</td>
                    <td style={tableStyle.cells}>{row.crPrimKeys}</td>
                    <td style={tableStyle.cells}>{row.crPerpetualKeys}</td>
                  </tr>
                  
                  {(index === (searchClicked ? searchedData.length - 1 : flattenedData.length - 1) || row.superDistributor !== (searchClicked ? searchedData[index + 1]?.superDistributor : flattenedData[index + 1]?.superDistributor)) && (
                    <tr style={tableStyle.totalRow}>
                      <td colSpan={4} style={{ ...tableStyle.cells, textAlign: 'right' }}>(Distributor SubTotal)&nbsp;&nbsp;</td>
                      <td style={tableStyle.cells}>
                        {calculateTotals(searchClicked ? searchedData.filter(r => r.superDistributor === row.superDistributor) : flattenedData.filter(r => r.superDistributor === row.superDistributor)).totalAvPrimKeys}
                      </td>
                      <td style={tableStyle.cells}>
                        {calculateTotals(searchClicked ? searchedData.filter(r => r.superDistributor === row.superDistributor) : flattenedData.filter(r => r.superDistributor === row.superDistributor)).totalAvPerpetual}
                      </td>
                      <td style={tableStyle.cells}>
                        {calculateTotals(searchClicked ? searchedData.filter(r => r.superDistributor === row.superDistributor) : flattenedData.filter(r => r.superDistributor === row.superDistributor)).totalCrTempKeys}
                      </td>
                      <td style={tableStyle.cells}>
                        {calculateTotals(searchClicked ? searchedData.filter(r => r.superDistributor === row.superDistributor) : flattenedData.filter(r => r.superDistributor === row.superDistributor)).totalAcTempKeys}
                      </td>
                      <td style={tableStyle.cells}>
                        {calculateTotals(searchClicked ? searchedData.filter(r => r.superDistributor === row.superDistributor) : flattenedData.filter(r => r.superDistributor === row.superDistributor)).totalCrPrimKeys}
                      </td>
                      <td style={tableStyle.cells}>
                        {calculateTotals(searchClicked ? searchedData.filter(r => r.superDistributor === row.superDistributor) : flattenedData.filter(r => r.superDistributor === row.superDistributor)).totalCrPerpetual}
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}

              <tr style={tableStyle.totalRow}>
                <td colSpan={4} style={{ ...tableStyle.cells, textAlign: 'right' }}>(Super Distributor SubTotal)&nbsp;&nbsp;</td>
                <td style={tableStyle.cells}>
                  {searchClicked ? searchTotals.totalAvPrimKeys : totals.totalAvPrimKeys}
                </td>
                <td style={tableStyle.cells}>
                  {searchClicked ? searchTotals.totalAvPerpetual : totals.totalAvPerpetual}
                </td>
                <td style={tableStyle.cells}>
                  {searchClicked ? searchTotals.totalCrTempKeys : totals.totalCrTempKeys}
                </td>
                <td style={tableStyle.cells}>
                  {searchClicked ? searchTotals.totalAcTempKeys : totals.totalAcTempKeys}
                </td>
                <td style={tableStyle.cells}>
                  {searchClicked ? searchTotals.totalCrPrimKeys : totals.totalCrPrimKeys}
                </td>
                <td style={tableStyle.cells}>
                  {searchClicked ? searchTotals.totalCrPerpetual : totals.totalCrPerpetual}
                </td>
              </tr>
            </tbody>
          </RB.Table>
        </div>
      </RB.Col>
    </RB.Row>
  </RB.Container>



    </>
  );
}

export default DistributorFullStockReport;
