import axios from "../../api/axios";
import React, { useEffect, useMemo, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import { useNavigate, useLocation } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import Button from 'react-bootstrap/Button';
import ConfirmDialog from "../ConfirmDialog";
import SnackbarAlerts from "../SnackbarAlerts";
import AddKeyPopup from "./AddKeyPopup";
import { TablesFilterSeller, FilterByUsers } from "../../Data/Data"
import Popover from 'react-bootstrap/Popover';
import { enqueueSnackbar } from 'notistack';
import { ErrorMsgs, Variables } from '../../Data/Data'
import { TbFileDescription } from 'react-icons/tb';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { RiSettings3Line } from 'react-icons/ri';
import View from '../KeyGenerate/View';
import DataContext from '../../context/DataContext';
import AuthProvider from '../../context/AuthProvider';
import KeyGenData from '../../context/KeyGenData';
import "./UserTable.css";
import Spinner from 'react-bootstrap/Spinner';

const USER_URL = process.env.REACT_APP_API_SELLER_FIND_ALL_SELLERS;
const USER_DELETE = process.env.REACT_APP_API_USER_DELETE;
const SELLER_LIST = process.env.REACT_APP_API_SELLER_ID;//Seller ID
const ACCESS_BY_ACCESS = process.env.REACT_APP_API_USER_ACCESS_GET_USER_ACCESS_BY_ACCESS;
const CHECK_KEY_EXTEND = process.env.REACT_APP_API_CHECK_KEY_EXTEND;

const SalesReport = () => {

  let variant

  const location = useLocation();
  const navigate = useNavigate()
  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState(0);
  const [sellId, setSellId] = useState(0);
  //const [openAlert, setOpenAlert] = useState(false);
  //const [alertMsg, setAlertMsg] = useState('');
  const [isBoxChecked, setIsBoxChecked] = useState(false)
  //const editConfirm = "Are you sure you want to update this user?"
  //const [openDelete, setOpenDelete] = useState(false)
  //const [openView, setOpenView] = useState(false)
  //const [sellers, setSellers] = useState([]);
  const [keyList, setKeyList] = useState([]);
  const [isExtended, setIsExtended] = useState(false);
  const [sellerName, setSellerName] = useState('');
  const [loading, setLoading] = useState(false);
  
 // const deleteConfirm = "Are you sure you want to delete this user?"

  const { setActionView,  actionView, nameSearch, sellerKeys, setSellerKeys, setSellerSearch, sellerSearch, setUserSearch, activeKeyGenIdSeller, setActiveKeyGenIdSeller, isExtendedKey, setDisabledKeyGenIdSeller, disabledKeyGenIdSeller, setIsExtendedKey, fullName,setDataId } = useContext(DataContext)
  const { addKeyAccess, partnerAccess, hasCreatePartnerPermission, extendKeyAccess, setHasCreatePartnerPermission, sellerReportsPrint } = useContext(AuthProvider)
  const { setValue , value} = useContext(KeyGenData)

 // const [severity, setServerity] = useState('success')
  //const [openAddKey, setOpenAddKey] = useState(false);
  //const [customerName, setCustomerName] = useState('');
  const [searchBy, setSearchBy] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);
  const [type, setType] = useState(2);
  //const [sentRequest, setSendRequest] = useState(false)
  const [isKeyDisabled, setIsKeyDisabled] = useState(false);
  const [extendedKeyGenIds, setExtendedKeyGenIds] = useState([]);
 // const [refreshPage, setRefreshPage] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const maxUserCount = Variables.maxUserCount

  const columns = [
    {
      name: 'First Name',
      selector: row => row.fname,
      wrap: true,
      width: '120px',
    },
    {
      name: 'Last Name',
      selector: row => row.lname,
      wrap: true,
      width: '120px',
    },
    {
      name: 'Vat No',
      selector: row => row.vatNo,
      wrap: true,
    },
   
    {
      name: 'Company Name',
      selector: row => row.companyName,
      wrap: true,
      width: '160px',
    },
    {
      name: 'Status',
      selector: row => row.status === true ? 'Active' : 'Deactive',
      width: '70px',
    },
    
   
    {
      name: 'Telephone',
      selector: row => row.tpNo,
      width: '120px',
      wrap: true,
    },
    {
      name: 'Mobile',
      selector: row => row.mobileNo,
      width: '120px',
      wrap: true,
    },
    {
      name: 'Email',
      selector: row => row.email,
      wrap: true,
      width: '200px',
    },
   
    /* {
      name: "Actions",
      button: true,
      cell: (row) => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <input 
            type="checkbox" 
            id={`checkbox-${row.id}`} 
            name={`checkbox-${row.id}`} 
            onChange={() => handleCheckboxChange(row.id, row.sellerId )} 
            style={{ width: '25px', height: '25px' }}
          />
        
        </div>
      ),
    }, */
    {
      name: "Actions",
      button: true,
      cell: (row) => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <input 
            type="radio" // Change the type to "radio"
            id={`radiobutton-${row.id}`} 
            name={`radiobutton-${row.id}`} 
            onChange={() => handleRadioChange(row)} // Adjust the event handler to handle radio button change
            style={{ width: '25px', height: '25px' }}
            checked={selectedRow === row} // Use checked property to determine if radio button is selected
          />
        </div>
      ),
    },
    
  ];

  const sellerColumn = [
    {
      name: 'UUID',
      selector: row => row.uuid,
      wrap: true,
    },
    {
      name: 'Customer',
      selector: row => row.customerName,
      wrap: true,
      width: '170px',
    },
    {
      name: 'Created on',
      selector: row => row.createdDate,
      width: '85px',
      wrap: true,
    },
    {
      name: 'Valid From',
      selector: row => row.validFrom,
      width: '85px',
    },
    {
      name: 'Valid To',
      selector: row => row.validTo,
      width: '85px',
    },
    {
      name: 'Key Type',
      selector: row => {
        if (row.keyType === 'PERMANENT') return 'Permanent';
        if (row.keyType === 'TEMPORARY') return 'Temporary';
        if (row.keyType === 'PERPETUAL') return 'Perpetual';
        return 'Unknown';
      },
      wrap: true,
      width: '85px',
    },
    
    {
      name: 'Days',
      selector: row => row.days,
      width: '50px',
    },

    {
      name: 'User Count',
      selector: row => (row.userCount >= maxUserCount) ? "Unlimited" : row.userCount,
      width: '80px',
    },

    {
      name: 'Description',
      width: '80px',
      selector: row => row.description || 'N/A',
    },
    {
      name: 'Seller Name',
      selector: (row) => row.sellerName, // Use the key from your API response
      wrap: true,
    },

    {
      name: 'Status',
      width: '140px',
      selector: row => (
        <div>
          {handleStatus(row)}
        </div>
      ),
    },
    
  ];


  const customStyles = {
    rows: {
      style: {
        minHeight: '60px', // override the row height
      },
    },
    headCells: {
      style: {
        borderStyle: 'solid',
        borderColor: '#40c4ff',
        borderWidth: '1px',
        borderCollapse: 'collapse',
        fontWeight: 'bold',
        padding: '5px',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#b2ebf2',
      },
    },
    cells: {
      style: {
        overFlowWrap: 'break-word',
        borderStyle: 'solid',
        borderColor: '#40c4ff',
        borderWidth: '1px',
        borderCollapse: 'collapse',
        padding: '5px',
        alignItems: 'center',
        justifyContent: 'center'

      },
    },
  };

  


  const fetchUsers = async (type) => {
    try {
      const response = await axios.get(USER_URL + `/${type}`);
      const status = response.data.status;
      const result = response.data.result;
      const msg = response.data.msg;
  
      if (status) {
        if (result && result.length > 0) {
          setUsers(result);
          setUserId(result[0].id); // Set userId here
          setHasCreatePartnerPermission(true);
        } else {
          setUsers([]);
          setHasCreatePartnerPermission(false);
        }
      } else {
        setUsers([]);
        enqueueSnackbar(msg, { variant: 'error' });
      }
    } catch (err) {
      if (!err.response) {
        enqueueSnackbar(ErrorMsgs[0].noResponse, { variant: 'error' });
      } else if (err.response !== 403) {
        enqueueSnackbar(ErrorMsgs[0].fetchError, { variant: 'error' });
      }
    }
  };
  

  const fetchSellers = async (sellerId, value, isDelete) => {
    try {
      setSellId(sellerId);
      const response = await axios.get(SELLER_LIST + `${sellerId}/${value}/${isDelete}`);
      //console.log('API Response:', response.data);
      const status = response.data.status;
      const result = response.data.result;
      const msg = response.data.msg;
      
      //console.log('API', response.data.result);
      if (status) {
        if (result) {
          const sellerNames = result.map(item => item.sellerName);
          setSellerName(sellerNames);
          
          return result; // Return the result if it exists
        } else {
          return []; // Return an empty array if result is not present
        }
      } else {
        variant = 'error';
        enqueueSnackbar(msg, { variant });
        return []; // Return an empty array in case of error
      }
    } catch (err) {
      variant = 'error';
      if (!err.response) {
        enqueueSnackbar(ErrorMsgs[0].noResponse, { variant });
      } else if (err.response !== 403) {
        enqueueSnackbar(ErrorMsgs[0].fetchError, { variant });
      }
      return []; // Return an empty array in case of error
    }
  };



const fetchExtendedStatus = async (keyGenId) => {
  try {
      const response = await axios.get(CHECK_KEY_EXTEND + `${keyGenId}/1`);
      const { result } = response.data;
      
      // Check if the data has the expected structure
      if (response.data.result.isExtended) {
          setIsExtended(true);
          setIsExtendedKey(true);
          const historyList = response.data.result.historyList;
          const keyGenIds = historyList.map(item => item.keyGen.id);
          
          // Update the extendedKeyGenIds state
          setExtendedKeyGenIds(prevIds => new Set([...prevIds, ...keyGenIds]));
          //console.log("Extended Ids", extendedKeyGenIds);
      }

      if (result !== undefined) {
          //console.log('Ex', response);
          return result;
      } else {
          console.warn('Invalid response format:', response.data);
          return false;
      }
  } catch (error) {
      console.warn('Error while checking extended status');
      return false;
  }
};



 // console.log(isExtended)

  //console.log(users); 
  //console.log('userid',userId); 

  /* const handleRadioChange = async (sellerId) => {
    const isChecked = document.getElementById(`checkbox-${sellerId}`).checked;
    console.log('Checkbox checked:', isChecked);
   
    if (isChecked) {
      setIsBoxChecked(true);
      try {
        const activeKeys = await fetchSellers(sellerId, '1', 0); // Active keys
        const disabledKeys = await fetchSellers(sellerId, '0', 0); // Disabled keys value
        const combinedKeys = [...activeKeys, ...disabledKeys];
        setActiveKeyGenIdSeller(activeKeys);
        setDisabledKeyGenIdSeller(disabledKeys);
        const activeKeyIds = Array.isArray(activeKeyGenIdSeller) ? activeKeyGenIdSeller.map((key) => key.keyGenId) : [];
        const disabledKeyIds = Array.isArray(disabledKeyGenIdSeller) ? disabledKeyGenIdSeller.map((key) => key.keyGenId) : [];
        const keysWithExtendedStatus = await Promise.all(
          combinedKeys.map(async (key) => {
            const isExtended = await fetchExtendedStatus(key.keyGenId);
            return { ...key, isExtended, isExtendedKey };
          })
        );
        setKeyList(keysWithExtendedStatus);
        setSellerKeys(keysWithExtendedStatus);
        
        const isAnyKeyDisabled = disabledKeys.length > 0;
        setIsKeyDisabled(isAnyKeyDisabled);
        setFilteredItems([userData.find(user => user.id === sellerId)]);
        // Clear the sellerKeys state to hide the second DataTable
        
      } catch (err) {
        console.error('Cannot fetch data', err); // Handle any errors here
      }
    } else {
      // If checkbox is unchecked, show all rows in the first DataTable
      setFilteredItems(userData);
      setIsBoxChecked(false);
      setSellerKeys([]);
    }
  }; */
  const handleRadioChange = async (row) => {
    setLoading(true);
    try {
      const sellerId = row.id; // Assuming 'id' is the unique identifier for each row
      const activeKeys = await fetchSellers(sellerId, '1', 0); // Active keys
      const disabledKeys = await fetchSellers(sellerId, '0', 0); // Disabled keys value
      const combinedKeys = [...activeKeys, ...disabledKeys];
      setActiveKeyGenIdSeller(activeKeys);
      setDisabledKeyGenIdSeller(disabledKeys);
      const activeKeyIds = Array.isArray(activeKeyGenIdSeller) ? activeKeyGenIdSeller.map((key) => key.keyGenId) : [];
      const disabledKeyIds = Array.isArray(disabledKeyGenIdSeller) ? disabledKeyGenIdSeller.map((key) => key.keyGenId) : [];
      const keysWithExtendedStatus = await Promise.all(
        combinedKeys.map(async (key) => {
          const isExtended = await fetchExtendedStatus(key.keyGenId);
          return { ...key, isExtended, isExtendedKey };
        })
      );
      setKeyList(keysWithExtendedStatus);
      setSellerKeys(keysWithExtendedStatus);
      
      const isAnyKeyDisabled = disabledKeys.length > 0;
      setIsKeyDisabled(isAnyKeyDisabled);
      setFilteredItems([row]); // Set the filtered items to the clicked row
      setSelectedRow(row); // Set the selected row
    } catch (err) {
      console.error('Cannot fetch data', err); // Handle any errors here
    } finally {
      setLoading(false); // End loading
    }
  };
  
 
  const handleStatus = (row) => {
    const activeKeyIds = Array.isArray(activeKeyGenIdSeller) ? activeKeyGenIdSeller.map((key) => key.keyGenId) : [];
    const isKeyActive = activeKeyIds.includes(row.keyGenId);
    const extendedKeyIds = Array.from(extendedKeyGenIds);
    const isValid = new Date(row.validTo) < new Date();

    //console.log('Active Key IDs:', activeKeyIds);
    //console.log('Extended Key IDs:', extendedKeyIds);

    if (row.isGenerated) {
        if (isKeyActive) {
          if(isValid){
            return 'Expired';
          }
           else if (extendedKeyIds.length > 0 && extendedKeyIds.includes(row.keyGenId)) {
                // Key is active and extended
                return 'Extended';
            } else {
                // Key is active but not extended
                return 'Generate';
            }
        } else {
            // Key is generated but not active
            return 'Disabled';
        }
    } else {
        // Key is not generated
        return 'Assign';
    }
};



  useEffect(() => {
    
  }, [sellerName])
  
   //console.log("Name ", sellerName)

   useEffect(() => {
    fetchUsers(type);
    setSellerKeys([]);
    // Check if sellId is not 0 before making the API request
    if (sellId !== 0) {
      fetchSellers(sellId, value, 0);
    }
  }, []);
  
  

  const userData = useMemo(() => [...users], [users]);
  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

  useEffect(() => {
    //filter data for search
    if (searchBy === 'fname') {
      setFilteredItems(userData.filter(item =>
        item.fname && item.fname.toLowerCase().includes(filterText.toLowerCase()),
      ));
    } else if (searchBy === 'lname') {
      setFilteredItems(userData.filter(item =>
        item.lname && item.lname.toLowerCase().includes(filterText.toLowerCase()),
      ));
    } else if (searchBy === 'vatNo') {
      setFilteredItems(userData.filter(item =>
        item.vatNo && item.vatNo.toLowerCase().includes(filterText.toLowerCase()),
      ));
    } else if (searchBy === 'tpNo') {
      if (filterText !== '') {
        const filterNumber = parseInt(filterText);
        setFilteredItems(userData.filter(item => item.tpNo && item.tpNo.toString().includes(filterNumber.toString())));
      } else {
        setFilteredItems(userData);
      }
    } else if (searchBy === 'mobileNo') {
      if (filterText !== '') {
        const filterNumber = parseInt(filterText);
        setFilteredItems(userData.filter(item => item.mobileNo && item.mobileNo.toString().includes(filterNumber.toString())));
      } else {
        setFilteredItems(userData);
      }
    } else if (searchBy === 'email') {
      setFilteredItems(userData.filter(item =>
        item.email && item.email.toLowerCase().includes(filterText.toLowerCase()),
      ));
    } else if (searchBy === 'id') {
      if (filterText !== '') {
        const filterNumber = parseInt(filterText);
        setFilteredItems(userData.filter(item => (item.id) === filterNumber));
      } else {
        setFilteredItems(userData);
      }
    }
    else {
      setFilteredItems(userData);
    }
  }, [searchBy, filterText, userData])

  useEffect(() => {
    fetchUsers(type);
    
  }, [type]) //searchBy, filteredItems
  
  useEffect(() => {
    if (!isBoxChecked) {
      // Reset the filter when checkbox is unchecked
      setFilterText('')
      setSearchBy('')
    }
  }, [isBoxChecked]);


  const handlePrint = () => {

    const currentDate = new Date();
    const printDateTime = currentDate.toLocaleDateString() + ' ' + currentDate.toLocaleTimeString();
    

    const content = `
    
    <html>
      <head>
        <!-- Include any necessary styles here -->
        <style>
          /* Define print-specific styles here */
          body {
            font-family: 'Calibri Light', sans-serif;
          }
          .print {
            margin-top: none;
          }
          .header {
            font-size: 16px;
            font-weight: bold;
            color: #888888; /* Light gray color */
          }
          .top-header {
            font-size: 16px;
            font-weight: bold;
            color: #595757; /* Light gray color */
          }
          .sub-header {
            font-size: 13px;
            font-weight: bold;
            color: #888888; /* Light gray color */
          }
          .content {
            font-size: 13px;
          }
          .table {
            width: 100%;
            border-collapse: collapse;
            border: solid #000 !important;
            border-width: 1px 1px 1px 1px !important;
          }
          .table tbody { 
            padding-bottom: 30px;  
          }
          .table th {
            border: 1px solid #dddddd; /* Light gray border */
            padding: 8px;
            text-align: left;
            border: solid #000 !important;
            border-width: 1px 1px 1px 1px !important;
          }
          .table td {
            border: 1px solid #a6a6f5; /* Dark blue border */
            background-color: #fefefe;
            padding: 8px;
            text-align: left;
            border: solid #000 !important;
            border-width: 1px 0 0 1px !important;
          }
          .table th {
            background-color: #f2f2f2; /* Light gray background */
            color: #595757; /* Light gray color */
          }
          .footer {
            position: fixed;
            bottom: -10.5px;
            width: 100%;
            text-align: right;
            padding: 10px 0;
            border-top: 1px solid #ddd; /* Light gray border */
            margin-top: 50px;
          }
        </style>
      </head>
      <body>
      <div class="print">
         <div class="top-header">
   
        <div style="display: flex; justify-content: space-between; align-items: center;">
        <p style="margin: 0; font-size: 29px;">LICENSE KEY DETAILS OF "${sellerKeys.length > 0 ? Array.from(new Set(sellerKeys.map(key => key.sellerName))).join(', ').toUpperCase() : 'N/A'}" SELLER</p>
        <div style="text-align: right;">
        &nbsp; 
            <p style="color: #888888; margin: 0;">Date: ${printDateTime}</p>
            <p style="color: #888888; margin: 0;">User Name: ${fullName.result ? (fullName.result.fname + (fullName.result.lname ? ' ' + fullName.result.lname : '')) : 'N/A'}</p>
        </div>
       </div>
</div>
</div>

    
        <br>
    
    
        <table class="table">
          <thead>
          <tr>
          <th style="border: 1px solid #ddd; padding: 8px;">Date</th>
          <th style="border: 1px solid #ddd; padding: 8px;">UUID</th>
          <th style="border: 1px solid #ddd; padding: 8px;">Description</th>
          <th style="border: 1px solid #ddd; padding: 8px;">Status</th>
          <th style="border: 1px solid #ddd; padding: 8px;">Customer Name</th>
          <th style="border: 1px solid #ddd; padding: 8px;">Users</th>
          <th style="border: 1px solid #ddd; padding: 8px;">Days</th>
          <th style="border: 1px solid #ddd; padding: 8px;">From Date</th>
          <th style="border: 1px solid #ddd; padding: 8px;">To Date</th>
          <th style="border: 1px solid #ddd; padding: 7px;">Key Type</th>
        </tr>
          </thead>
          <tbody>
            ${sellerKeys.map(item => `
              <tr>
                <td style="border: 1px solid #ddd; padding: 8px;">${item.createdDate || 'N/A'}</td>
                <td style="border: 1px solid #ddd; padding: 8px;">${item.uuid || 'N/A'}</td>
                <td style="border: 1px solid #ddd; padding: 8px;">${item.description || 'N/A'}</td>
                <td style="border: 1px solid #ddd; padding: 8px;">${handleStatus(item)}</td> 
                <td style="border: 1px solid #ddd; padding: 8px;">${item.customerName || 'N/A'}</td>
                <td style="border: 1px solid #ddd; padding: 8px;">${item.userCount || 'N/A'}</td>
                <td style="border: 1px solid #ddd; padding: 8px;">${item.days || 'N/A'}</td>
                <td style="border: 1px solid #ddd; padding: 8px;">${item.validFrom || 'N/A'}</td>
                <td style="border: 1px solid #ddd; padding: 8px;">${item.validTo || 'N/A'}</td>
                <td style="border: 1px solid #ddd; padding: 9px;">${item.keyType || 'N/A'}</td>
              </tr>
            `).join('')}
          </tbody>
        </table>
    
        <script>
          
    
            // Trigger the printing process
            window.print();
            window.close(); // Close the window after printing (optional)
          
    
          
        </script>
    
      </body>
    </html>
    
    
    `;

    // Open a new window for printing
    const printWindow = window.open('', '_blank', 'width=1000,height=800');
    if (printWindow) {
      printWindow.document.open();
      printWindow.document.write(content);
      printWindow.document.close();

    } else {
      console.error('Failed to open print window');
    }
  };


  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust the delay time as needed (2000 milliseconds = 2 seconds)
  
    // Cleanup function to clear the timeout
    return () => clearTimeout(timer);
  }, []);




 
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      
     
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    const handleRadioClear = () => {
      
      setSellerKeys([]);
      setIsBoxChecked(false); 
      setSelectedRow(null);
      fetchUsers(type);
      
    };
    return (
      <MDBCol md='12'>
        <MDBRow className='bg-blue p-3 mb-2'>
          <MDBCol md='3' className='mb-1 divider-right'>
            <Form.Group >
              <Form.Select
                id='keyTypeId'
                onChange={(e) => setType(e.target.value)}
                value={type}
                
              >
                <option disabled value=''>Filter By...</option>
                {FilterByUsers.map((options, index) => (
                  <option key={index} value={options.value}>{options.select}</option>
                ))}
              </Form.Select> 
            </Form.Group>
          </MDBCol>
          <MDBCol md='3' className='mb-1 divider-right'>
            <Form.Group >
              <Form.Select
                id='keyTypeId'
                onChange={(e) => setSearchBy(e.target.value)}
                value={searchBy}
              >
                <option disabled value=''>Search By...</option>
                {TablesFilterSeller.map((options, index) => (
                  <option key={index} value={options.value}>{options.select}</option>
                ))}
              </Form.Select>
            </Form.Group>
          </MDBCol>
          <MDBCol md='4' className='mb-1'>
          <MDBRow>
          <div className='d-flex align-items-center'>
            <MDBCol xs='10' md='10' className='me-0 pe-0'>
                <Form.Control
                  id="search"
                  type="text"
                  placeholder="Search..."
                  aria-label="Search Input"
                  value={filterText}
                  onChange={e => setFilterText(e.target.value)}
                />
              </MDBCol>
              <MDBCol xs='2' md='2' className='ms-0 ps-2 align-self-center'>
                <Button id="search-clear-button" type="button" onClick={handleClear}>
                  X
                </Button>
              </MDBCol>
              <MDBCol xs='2' md='2' className='ms-0 ps-2 align-self-center'>
                <Button id="search-clear-button" type="button" onClick={handleRadioClear}>
                  Reset 
                </Button>
              </MDBCol>
            </div>
            </MDBRow>
          </MDBCol>
         

          
        </MDBRow>
      </MDBCol>
    );
  }, [filterText, resetPaginationToggle, navigate, searchBy, type]);


  
 

  return (
    <>
      <DataTable
        noDataComponent={null}
        //pagination
        // paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        responsive
        customStyles={customStyles}
        
        
      />

      

      
      <div style={{ overflowY: 'auto' }}>
 
    <DataTable
      pagination
      columns={columns}
      data={filteredItems}
      paginationResetDefaultPage={resetPaginationToggle}
      responsive
      customStyles={customStyles}
    />
 
</div>



<div style={{ overflowY: 'auto' }}>
{loading ? (
      <div className="d-flex justify-content-center align-items-center">
        <Spinner animation="border" variant="primary" /> {/* Loading spinner */}
      </div>
    ) : (
      <>
        {sellerKeys.length > 0 && (
          <DataTable
            title={
              <div className="d-flex justify-content-between align-items-center">
                <div>Seller Keys</div>
                <div>
                {sellerReportsPrint && ( 
                  <Button onClick={handlePrint} color="primary">
                    Print
                  </Button>
                  )}
                </div>
              </div>
            }
            columns={sellerColumn}
            data={sellerKeys}
            pagination
            highlightOnHover
            responsive
            customStyles={customStyles}
          />
        )}
      </>
    )}
  </div>


      
    </>
  );
}

export default SalesReport