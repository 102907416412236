import React, { useEffect, useState, useRef, useContext } from 'react';
//import { MDBRow, MDBCol, MDBCard, MDBCardBody}from 'mdb-react-ui-kit';
import Form from 'react-bootstrap/Form';
import { Typeahead } from 'react-bootstrap-typeahead';
import axios from '../../api/axios';
import './TextCard.css';
import Button from 'react-bootstrap/Button';
import TypeAheadClear from '../TypeAheadClear';
import SnackbarAlerts from '../SnackbarAlerts';
import DialogYesNo from '../DialogYesNo';
import { useNavigate } from 'react-router-dom';
import { KeyTypes, DaysOptions, ErrorMsgs, Variables, YearsOptions } from '../../Data/Data';
import KeyGenData from '../../context/KeyGenData';
import DataContext from '../../context/DataContext';
import { enqueueSnackbar } from 'notistack';
import UnlimitedPopUp from './UnlimitedPopUp';
import { UilTimes, UilCheck } from "@iconscout/react-unicons";
import { Container, Row, Col, Card } from 'react-bootstrap';
import './OfflineActivation.css';
import './Assign.css';

const UUID_REGEX = /^[A-Z0-9]{5,7}-[A-Z0-9]{5,7}-[A-Z0-9]{5,7}-[A-Z0-9]{5,7}$/;
const CUSTOMER_BY_SELLER = process.env.REACT_APP_API_CUSTOMER_FIND_BY_SELLER_ID;
const KEY_ASSIGN = process.env.REACT_APP_API_KEY_ASSIGN
const KEY_WALLET_URL = process.env.REACT_APP_API_GET_KEY_WALLET;
const UUID_SEARCH = process.env.REACT_APP_API_CHECK_UUID;
const KEY_EDIT = process.env.REACT_APP_API_KEY_ASSIGN_KEY_EDIT;

const Assign = () => {

  let variant

  function useValidation(value, regex) {
    const [isValid, setIsValid] = useState(false);

    const validate = () => {
      setIsValid(regex.test(value));
    };

    return [isValid, validate];
  }

  const { uuidEdit, setUuidEdit, keyGenId, setValue } = useContext(KeyGenData)

  const customerRef = useRef(null);
  const navigate = useNavigate();
  const [isEditUuid, setIsEditUuid] = useState(false)

  const [customer, setCustomer] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [customerId, setCustomerId] = useState();
  const [noCustomer, setNoCustomer] = useState(false);
  const [customerFocus, setCustomerFocus] = useState(false);

  const [keyType, setKeyType] = useState('');

  const [open, setOpen] = useState(false);
  const [severity, setServerity] = useState('success');
  const [message, setMessage] = useState('');

  /* const [customDays, setCustomDays] = useState(''); */
  const [isChecked, setIsChecked] = useState(false);

  const minMonth = Variables.minMonth;
  const [months, setMonths] = useState(minMonth);
  const [selectedYears, setSelectedYears] = useState(minMonth);
  const [equivalentDays, setEquivalentDays] = useState('');
  const [checkMinMonths, setCheckMinMonths] = useState(false);
  const [nullMonths, setNullMonths] = useState(true)
  //const [monthsDisable, setMonthsDisable] = useState(false)

  const [days, setDays] = useState('15');
  const [nullDays, setNullDays] = useState(true)
  const [userCount, setUserCount] = useState('5');
  const [isMaxUCount, setIsMaxUCount] = useState(false)
  const [description, setDescription] = useState('');
  const [licenseKey, setLicenseKey] = useState('');
  const [userId, setUserId] = useState(0);
  const [editableUUID, setEditableUUID] = useState(false);

  const [keyWallet, setKeyWallet] = useState([]);
  const [available, setAvailable] = useState('');
  const [perpetualAvailable, setPerpetualAvailable] = useState('');
  const [freeAvailable, setFreeAvailable] = useState('');
  const [keys, setKeys] = useState(0);
  const [keyValidate, setKeyValidate] = useState(false);
  const [uuidValid, setUuidValid] = useState(false);

  const [status, setStatus] = useState(0)
  const [uuid, setUuid] = useState('');
  const [uuidChecked, setUuidChecked] = useState(true)
  const [usedUuid, setUsedUuid] = useState(false)
  const [validUuid, setValidUuid] = useState(false);
  const [isValidUuid, validateUuid] = useValidation(uuid, UUID_REGEX);
  const [uuidFocus, setUuidFocus] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [title, setTitle] = useState('');
  const [msg, setMsg] = useState('');
  const [uuidStatus, setUuidStatus] = useState(true);
  const [oldEssential, setOldEssential] = useState(0);
  const [essentialBig, setEssentialBig] = useState(false)
  const [sentRequest, setSendRequest] = useState(false)
  const val = 0

  const { isEmpty } = useContext(DataContext);

  const [openUnlimited, setOpenUnlimited] = useState(false)
  const [subscription, setSubscription] = useState(false);
  const [prevUserCount, setPrevUserCount] = useState('5')
  const [isEdit, setIsEdit] = useState(false);
  const [unlimited, setUnlimited] = useState(false)
  const [years, setYears] = useState('1')
  const maxUserCount = Variables.maxUserCount

  const keyWalletFetch = async () => {
    try {
      const response = await axios.get(KEY_WALLET_URL);
      const status = response.data.status
      const result = response.data.result
      const msg = response.data.msg
      if (status) {
        if (result) {
          setKeyWallet(result);
        } else {
          setKeyWallet({ available: 0, freeAvailable: 0 })
        }
      } else {
        setKeyWallet({ available: 0, freeAvailable: 0 })
        variant = 'error'
        enqueueSnackbar(msg, { variant })
      }
    } catch (err) {
      variant = 'error'
      if (!err.response) {
        enqueueSnackbar(ErrorMsgs[0].noResponse, { variant })
      } else if (err.response !== 403) {
        enqueueSnackbar(ErrorMsgs[0].fetchError, { variant })
      }
    }
  }

  const CustomerFetch = async (value) => {
    try {
      const response = await axios.get(CUSTOMER_BY_SELLER + `/${value}`);
      const status = response.data.status
      const result = response.data.result
      if (status) {
        if (result) {
          setCustomerOptions(result.filter(item => item.status === true));
        } else {
          setCustomerOptions([]);
        }
      } else {
        setCustomerOptions([]);
      }
    } catch (err) {
      variant = 'error'
      if (!err.response) {
        enqueueSnackbar(ErrorMsgs[0].noResponse, { variant })
      } else if (err.response !== 403) {
        enqueueSnackbar(ErrorMsgs[0].fetchError, { variant })
      }
    }
  }

  useEffect(() => {
    document.title = 'Key Assign';
    keyWalletFetch();
    CustomerFetch(val);
  }, [])

  useEffect(() => {
    if (uuidEdit !== '') {
      setUuid(uuidEdit);
      setIsEditUuid(true);
    }
  }, [uuidEdit])

  //validate uuid
  /*  useEffect(() => {
     setValidUuid(UUID_REGEX.test(uuid));
     if (uuid.includes(' ')) {
       setUuid(uuid.trim())
     }
   }, [uuid]) */

  //validate uuid
  useEffect(() => {
    if (uuid) {
      setValidUuid(UUID_REGEX.test(uuid));
      if (uuid.includes(' ')) {
        setUuid(uuid.trim())
      }
    }
  }, [uuid]);


  //set default customer to update`key
  useEffect(() => {
    if ((customerOptions).find(option => option.id === customerId)) {
      setCustomer([(customerOptions).find(option => option.id === customerId)]);
    }
  }, [customerOptions, customerId]);

  //set available and freeUsed from key wallet
  // freeUsed = temporary generated
  useEffect(() => {
    setAvailable(keyWallet.available);
    setFreeAvailable(keyWallet.freeAvailable);
    setPerpetualAvailable(keyWallet.perpetualAvailable);
  }, [keyWallet])

  useEffect(() => {
    //check whether months or days null to enable button
    if (keyType === 'Permanent' || keyType === 'Perpetual') {
      if (months === '') {
        setNullMonths(true)
        setNullDays(false)
      } else {
        setNullMonths(false)
        setNullDays(false)
      }
    } else if (keyType === 'Temporary') {
      if (days === '') {
        setNullDays(true)
        setNullMonths(false)
      } else {
        setNullMonths(false)
        setNullDays(false)
      }
    }

    //get the essential key count on months/days and userCount change
    if (selectedYears !== '' && userCount !== '') {
      let monthsInt = parseInt(selectedYears)
      let daysInt = parseInt(days)
      let userCountInt = parseInt(userCount)
      if (keyType === 'Permanent') {
        let essentialKeys = monthsInt * userCountInt
        //get new essential keys for edit 
        if (oldEssential > essentialKeys) {
          setEssentialBig(true)
          setKeys(oldEssential - essentialKeys)
        } else {
          setEssentialBig(false)
          setKeys(essentialKeys - oldEssential)
        }
      } else if (keyType === 'Temporary') {
        let essentialKeys = daysInt * userCountInt
        //get new essential keys for edit 
        if (oldEssential > essentialKeys) {
          setEssentialBig(true)
          setKeys(oldEssential - essentialKeys)
        } else {
          setEssentialBig(false)
          setKeys(essentialKeys - oldEssential)
        }
      } else if (keyType === 'Perpetual') {
        let essentialKeys = monthsInt * userCountInt
        //get new essential keys for edit 
        if (oldEssential > essentialKeys) {
          setEssentialBig(true)
          if (subscription) {
            setKeys(oldEssential - essentialKeys)
          } else {
            setKeys(0)
          }
          //
          //
        } else {
          setEssentialBig(false)
          if (subscription) {
            setKeys(essentialKeys - oldEssential)
          } else {
            setKeys(0)
          }

        }
      }
      else if (keyType === '') {
        setKeys(0)
      }
    }
    /* if(months !== '' && keyType === 'Permanent')  {
      if(months < 12){
        setCheckMinMonths(true)
      }else{
        setCheckMinMonths(false)
      }
    }else{
      setCheckMinMonths(false)
    } */
  }, [selectedYears, userCount, days, subscription, keyType, oldEssential])

  //set years according to months
  /* useEffect(() => {
    if(years !== ''){
      let yearsInt = parseInt(years)
      if(yearsInt === 1){
        setMonths('12')
        setMonthsDisable(true)
      }else if(yearsInt === 2){
        setMonths('24')
        setMonthsDisable(true)
      }else if(yearsInt === 3){
        setMonths('36')
        setMonthsDisable(true)
      }else{
        setMonths('12')
        setMonthsDisable(false)
      }
    }
  }, [years]) */

  //validate essential keys on keys change
  useEffect(() => {
    if (keyType === 'Permanent') {
      if (keys > available) {
        setKeyValidate(true)
      } else {
        setKeyValidate(false)
      }
    } else if (keyType === 'Perpetual') {
      if (keys > available) {
        setKeyValidate(true)
      } else {
        setKeyValidate(false)
      }
    }
    else if (keyType === 'Temporary') {
      if (keys > freeAvailable) {
        setKeyValidate(true)
      } else {
        setKeyValidate(false)
      }
    }
  }, [keys, available, freeAvailable, keyType])

  useEffect(() => {
    //when there are no available free key gives an alert
    if (available === 0 && freeAvailable === 0 && uuidEdit === '') {
      setOpenDialog(true);
      setTitle('No keys to generate!');
      setMsg('You have no keys available. Do you want to extend or view existing keys?');
    } else if (available === 0 && freeAvailable > 0) {
      setIsChecked(true)
    } else if (perpetualAvailable === 0 && freeAvailable > 0) {
      setIsChecked(true)
    } else if (perpetualAvailable > 0 && available === 0) {
      setIsChecked(false)
    } else if (perpetualAvailable === 0 && available > 0) {
      setIsChecked(true)
    } else if (perpetualAvailable > 0 && freeAvailable === 0) {
      setIsChecked(false)
    }
    else if (available > 0 && freeAvailable === 0) {
      setIsChecked(false)
    }
  }, [available, freeAvailable, perpetualAvailable, uuidEdit])

  const onYes = () => {
    setOpenDialog(false);
    navigate('/dashboard')
  }

  const onView = () => {
    setOpenDialog(false);
    setValue(3)
  }

  const onExtend = () => {
    setOpenDialog(false);
    setValue(2)
  }

  //check whether customer is empty for validation
  const validatecustomer = () => {
    if (noCustomer) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    //clear inputs when selected customer change  
    if (customerId === 0) {
      setKeyType('');
      setDays('15')
      setUserCount('5')
      setMonths(minMonth)
      setDescription('')
      setCustomer([])
    }
  }, [customerId, minMonth])

  /*  const uuidSearch = async () => {
     try {
       const response = await axios.get(UUID_SEARCH + `${uuid}`);
       const data = response.data
       const result = data.result
       setUuidStatus(data.status);
       if (data.status && uuidEdit === '') {
         setUuidChecked(true)
         setUsedUuid(true)
       } else if (data.status && uuidEdit !== '') {
         setUuidChecked(false)
         setUsedUuid(false)
         setIsEdit(true)
         setCustomerId(result.cuId)
         if (result.keyType === 'PERMANENT') {
           setKeyType('Permanent')
           setOldEssential((result.month) * (result.userCount))
         } else if (result.keyType === 'PERPETUAL') {
           setKeyType('Perpetual')
           setOldEssential((result.month) * (result.userCount))
         } else {
           setKeyType('Temporary')
           setOldEssential((result.days) * (result.userCount))
         }
         setDays(result.days)
         setMonths(result.month)
         setUserCount(result.userCount)
         setPrevUserCount(result.users)
         setDescription(result.description)
         setUserId(result.sellerDAO.id)
       } else {
         setUuidChecked(false)
         setUsedUuid(false)
       }
       setUuidEdit('')
     } catch (err) {
       variant = 'error'
       if (!err.response) {
         //enqueueSnackbar(ErrorMsgs[0].noResponse, { variant })
       } else if (err.response !== 403) {
         enqueueSnackbar('UUID Check Failed!', { variant })
       }
     }
   }
  */

  const uuidSearch = async () => {
    try {
      const response = await axios.get(UUID_SEARCH + `${uuid}`);
      const data = response.data
      //console.log('key', data)
      //console.log('key status', data.status)
      setUuidStatus(data.status);

      if (data.status === true) {
        if (data.result.isGenerated === false) {
          setUuidChecked(false)
          setUsedUuid(true)
          setEditableUUID(true);
          variant = 'success';
          enqueueSnackbar('A valid Editable UUID.', { variant });
          const today = new Date().toISOString().split('T')[0]; // Get today's date
          const validTo = data.result.validTo;
          setUuidValid(true);
        } else {
          setUuidChecked(true)
          setUsedUuid(false)
          setUuidValid(false)
          setEditableUUID(false)
          variant = 'error';
          enqueueSnackbar('A key is already assigned for this UUID', { variant });
        }


      }

      else if (data.status === false) {

        setUuidChecked(false)
        setUsedUuid(true)
        variant = 'success';
        enqueueSnackbar('A valid UUID.', { variant });
        const today = new Date().toISOString().split('T')[0]; // Get today's date
        const validTo = data.result.validTo;
        setUuidValid(true);

      } else {
        setUuidChecked(true)
        setUsedUuid(false)
        setUuidValid(false)
        variant = 'error';
        enqueueSnackbar('A key is already assigned for this UUID', { variant });
      }
    } catch (err) {
      variant = 'error'
      if (!err.response) {
        //enqueueSnackbar(ErrorMsgs[0].noResponse, { variant })
      } else if (err.response !== 403) {
        enqueueSnackbar(ErrorMsgs[0].fetchError, { variant })
      }
    }
  }

  useEffect(() => {
    if (uuidEdit !== '') {
      setUuid(uuidEdit);
      if (uuid !== '') {
        uuidSearch()
        setUuidEdit('');
      }
    }
  }, [uuidEdit, uuid])

  useEffect(() => {
    //open unlimited popup if user count is 75 or more     
    if (userCount !== '') {
      setUserCount(parseInt(userCount))
    }

    if (typeof userCount === "number") {
      if (userCount >= maxUserCount && !isEdit) {
        setOpenUnlimited(true);
        setUserCount(maxUserCount);
        setUnlimited(true)
      } else if (userCount >= maxUserCount && isEdit) {
        setOpenUnlimited(false);
        setUserCount(maxUserCount);
        setUnlimited(true)
      } else {
        setUnlimited(false)
      }
      if (isEdit && userCount !== prevUserCount) {
        setIsEdit(false)
      }
    }
  }, [userCount, maxUserCount, isEdit, prevUserCount])

  useEffect(() => {
    if (openUnlimited && !unlimited) {
      setUserCount(prevUserCount)
      console.log(prevUserCount)
    }
  }, [openUnlimited, unlimited, prevUserCount, userCount])

  useEffect(() => {
    //if unlimited popup is opened and turn off unlimited clear user count field 
    if (openUnlimited && !unlimited) {
      setUserCount('5')
      //console.log('Testing 1')
    } else if (unlimited) {
      setUserCount(maxUserCount)
    }
  }, [unlimited, openUnlimited, maxUserCount])


  useEffect(() => {
    //clear inputs when selected customer change  
    if (keyType === 'Perpetual') {

      setUserCount('50')

    } else {
      setUserCount('5')
    }
  }, [keyType])


  //handle submit method
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSendRequest(true)
    //check whether uuid is used
    try {
      const response = await axios.get(UUID_SEARCH + `${uuid}`);
      const data = response.data
      //if uuid is already used clear the fields return
      if (data.status) {
        setSendRequest(false)
        setUuidChecked(true)
        setUsedUuid(true)
        setKeyType('');
        setDays('15')
        setUserCount('5')
        setMonths(minMonth)
        setDescription('')
        setCustomer([])
        setCustomerFocus(false)
        setSubscription(false);
        setUnlimited(false)
        return;

        //else generate key
      } else {
        setUuidChecked(false)
        setUsedUuid(false)
        let customerId = customer[0].id;
        let monthsInt = parseInt(months)
        let daysInt = parseInt(days)
        if (keyType === 'Temporary') {
          monthsInt = 0;
        } else {
          daysInt = 0;
        }
        let userCountInt = parseInt(userCount)
        let keyTypeUpper = keyType.toUpperCase()

        const equivalentPerDays = subscription ? parseInt(selectedYears) * 372 : 0;

        const getNewKey = {
          cuId: customerId,
          customDate: "2012-01-01",
          uuid,
          description,
          keyType: keyTypeUpper,
          userCount: userCountInt,
          subscription,
          userId,
          isFree: isChecked
        };

        if (keyType === 'Permanent') {
          getNewKey.days = equivalentDays; // Use equivalentDays for permanent keys
          //getNewKey.months = 0;
        } else if (keyType === 'Perpetual') {
          getNewKey.days = equivalentPerDays; // Use equivalentDays for permanent keys
          //getNewKey.months = 0;
        }
        else if (keyType === 'Temporary') {
          getNewKey.days = daysInt; // Use daysInt for temporary keys
          //getNewKey.months = 0; // Set months to 0 for temporary keys
        }

        try {
          const response = await axios.post(KEY_ASSIGN, getNewKey);
          setSendRequest(false)
          const data = response.data
          if (data.status) {
            setLicenseKey(data.result.fullkey);
            variant = 'success'
            enqueueSnackbar('Key Assigning successful!', { variant })
          } else {
            variant = 'error'
            enqueueSnackbar('Key Assigning Failed!', { variant })
          }
          setCustomer([])
          setCustomerFocus(false)
          setKeyType('')
          setDays('15')
          setUserCount('5')
          setSelectedYears(minMonth)
          setUuid('')
          setDescription('')
          setKeys(0)
          setStatus(2)
          setUuidChecked(true)
          setUsedUuid(false)
          setUuidFocus(false);
          keyWalletFetch();
          setUuidEdit('');
          setSubscription(false);
          setUnlimited(false)

        } catch (err) {
          setSendRequest(false)
          variant = 'error'
          if (!err.response) {
            enqueueSnackbar(ErrorMsgs[0].noResponse, { variant })
          } else if (err.response !== 403) {
            enqueueSnackbar('Key Assigning Failed!', { variant })
          }
        }
      }
    } catch (err) {
      setSendRequest(false)
      variant = 'error'
      if (!err.response) {
        //enqueueSnackbar(ErrorMsgs[0].noResponse, { variant })
      } else if (err.response !== 403) {
        enqueueSnackbar('UUID Check Failed!', { variant })
      }
    }
  }





  //handle submit method
  const handleEdit = async (e) => {
    e.preventDefault();
    setSendRequest(true)
    //check whether uuid is used
    try {
      const response = await axios.get(UUID_SEARCH + `${uuid}`);
      setIsEditUuid(false)
      const data = response.data
      //if uuid is already used edit Key
      if (data.status) {
        setUuidChecked(false)
        setUsedUuid(false)
        let customerId = customer[0].id;
        let monthsInt = parseInt(months)
        let daysInt = parseInt(days)
        if (keyType === 'Temporary') {
          monthsInt = 0;
        } else {
          daysInt = 0;
        }
        let userCountInt = parseInt(userCount)
        let keyTypeUpper = keyType.toUpperCase()

        const equivalentPerDays = subscription ? parseInt(selectedYears) * 372 : 0;

        /*   const getNewKey = {
            cuId: customerId, customDate: "2012-01-01", days: daysInt, uuid, id: keyGenId,
            description, keyType: keyTypeUpper, months: monthsInt, userCount: userCountInt, userId, isFree: isChecked
          } */
        const getNewKey = {
          cuId: customerId,
          customDate: "2012-01-01",
          uuid,
          id: keyGenId,
          description,
          keyType: keyTypeUpper,
          userCount: userCountInt,
          subscription,
          userId,
          isFree: isChecked
        };

        if (keyType === 'Permanent') {
          getNewKey.days = equivalentDays; // Use equivalentDays for permanent keys
          //getNewKey.months = 0;
        } else if (keyType === 'Perpetual') {
          getNewKey.days = equivalentPerDays; // Use equivalentDays for permanent keys
          //getNewKey.months = 0;
        }
        else if (keyType === 'Temporary') {
          getNewKey.days = daysInt; // Use daysInt for temporary keys
          //getNewKey.months = 0; // Set months to 0 for temporary keys
        }

        try {
          const response = await axios.post(KEY_EDIT, getNewKey);
          setSendRequest(false)
          const data = response.data
          if (data.status) {
            setLicenseKey(data.result.fullkey);
            variant = 'success'
            enqueueSnackbar('Key Edit Successful!', { variant })
          } else {
            variant = 'error'
            enqueueSnackbar('Key Edit Failed!', { variant })
          }
          setCustomer([])
          setCustomerFocus(false)
          setKeyType('')
          setDays('15')
          setUserCount('5')
          setSelectedYears(minMonth)
          setUuid('')
          setDescription('')
          setKeys(0)
          setStatus(2)
          setUuidChecked(true)
          setUsedUuid(false)
          setUuidFocus(false);
          keyWalletFetch();
          setUuidEdit('');

        } catch (err) {
          setSendRequest(false)
          setOpen(true)
          setServerity('error')
          if (!err.response) {
            setMessage('No Server Response');
          } else {
            setMessage('Key edit fail!')
          }
        }
        //else return
      } else {
        setSendRequest(false)
        setUuidChecked(true)
        setUsedUuid(true)
        setKeyType('');
        setDays('15')
        setUserCount('5')
        setSelectedYears(minMonth)
        setDescription('')
        setCustomer([])
        setCustomerFocus(false)
        return;
      }
    } catch (err) {
      setSendRequest(false)
      variant = 'error'
      if (!err.response) {
        enqueueSnackbar(ErrorMsgs[0].noResponse, { variant })
      } else if (err.response !== 403) {
        enqueueSnackbar('UUID Check Failed!', { variant })
      }
    }
  }

  const handleKeyDown = (event) => {
    // check if the key pressed is not a number or a control key
    if (!((event.keyCode >= 48 && event.keyCode <= 57)
      || (event.keyCode >= 96 && event.keyCode <= 105)
      || event.keyCode === 8 || event.keyCode === 9
      || event.keyCode === 37 || event.keyCode === 39
      || (event.ctrlKey && (event.keyCode === 67 || event.keyCode === 86)) // allow Ctrl+C and Ctrl+V
      || (event.metaKey && (event.keyCode === 67 || event.keyCode === 86)) // allow Cmd+C and Cmd+V on macOS
    )) {
      event.preventDefault();
    }
  };


  const handleUnlimited = () => {
    if (unlimited) {
      setOpenUnlimited(false);
      setUnlimited(false);
      setUserCount(5); // Set uCountId to 5 when unlimited is unchecked
    } else {
      setOpenUnlimited(true);
      setUnlimited(true);
    }
  };

  const handleSubscription = (e) => {
    setSubscription(e.target.checked);
  };


  useEffect(() => {
    if (selectedYears !== '') {
      // Calculate equivalent days based on selected years (1 year = 372 days)
      const days = parseInt(selectedYears) * 372;
      setEquivalentDays(days);
    }
  }, [selectedYears]);




  return (
    <>

      <SnackbarAlerts
        open={open}
        setOpen={setOpen}
        msg={message}
        severity={severity}
      />
      <DialogYesNo
        open={openDialog}
        title={title}
        msg={msg}
        setOpen={openDialog}
        onBack={() => onYes()}
        onExtend={() => onExtend()}
        onView={() => onView()}
      />
      <UnlimitedPopUp
        open={openUnlimited}
        setOpen={setOpenUnlimited}
        unlimited={unlimited}
        setUnlimited={setUnlimited}
        userCount={userCount}
        setUserCount={setUserCount}
        setYears={setYears}
        years={years}
        keyType={keyType}
      />
      <Card className='card-assign mt-3'>
        <Card.Body className='p-0'>
          <Row>
            <Col md='12' className='bg-blue p-3 p-md-5'>

              <p className='user-instructions p-2' style={{ display: 'flex', justifyContent: 'center' }}>To begin the key assigning process enter the UUID and check the status of UUID.</p>


              <Form>
                <Row>
                  <Col md='12' className='mb-1 mu-1'>
                    <Form.Group className='mb-3 me-0 pe-0' >
                      <Form.Label className='white-lable me-0 pe-0' >UUID</Form.Label>
                      <span className="mandatory"> *</span>
                      <div style={{ display: 'flex' }}>
                        <Form.Control
                          className={
                            !isEmpty(uuid)
                              ? (!uuidValid && uuidChecked
                                ? 'not-valid'
                                : 'valid-field'
                              )
                              : ''

                          }
                          type='text'
                          id='vatCodeid'
                          onChange={(e) => setUuid(e.target.value)}
                          required
                          value={uuid}
                          onFocus={() => setUuidFocus(true)}
                          disabled={!uuidChecked}
                          onBlur={validateUuid}
                        />
                        <Button
                          className='uuid-search ms-2'
                          variant='light'
                          onClick={() => uuidSearch()}
                          disabled={!validUuid || !uuidChecked}
                        >
                          check
                        </Button>
                      </div>

                      {/* <span className={usedUuid && !isEmpty(uuid) ? 'validate' : 'hide'}>A key is already assigned for this UUID.</span> */}
                      <p className={uuidFocus && !validUuid && !isEmpty(uuid) ? 'validate' : 'hide'}>Invalid UUID</p>
                      {/*  <p className={!usedUuid && uuidFocus && isValidUuid && uuidChecked ? 'validate' : 'hide'} style={{ color: '#038a3b' }} >Valid UUID</p> */}
                      {/*  Validate message for UUID */}

                    </Form.Group>
                  </Col>
                </Row>
                <fieldset
                  disabled={uuidChecked}
                  className={'fieldset-invisible'}
                >
                  <Row>
                    <Col md='6' className='cust-column'>
                      <Form.Group className='mb-3' >
                        <Form.Label className='white-lable' size='sm'>Customers</Form.Label>
                        <span className="mandatory"> *</span>
                        <Typeahead
                          id="customersId"
                          labelKey={(customerOptions) => `${customerOptions.fname} ${customerOptions.lname}`}
                          onChange={(selected) => setCustomer(selected)}
                          options={customerOptions}
                          placeholder="Customer..."
                          selected={customer}
                          value={customer}
                          inputProps={{ required: true }}
                          ref={customerRef}
                          onFocus={() => setCustomerFocus(true)}
                          onBlur={validatecustomer}
                          style={{ display: 'flex' }}
                        ><TypeAheadClear select={customer} setSelect={setCustomer} /></Typeahead>
                        <p className={customer.length === 0 && customerFocus ? "validate" : "offscreen"}>
                          No matching customer found. Please add a new customer.<br />
                        </p>
                      </Form.Group>
                    </Col>
                    <Col md='6' className='custom-column '>
                      <Form.Group className='mb-3' >
                        <Form.Label className='white-lable' size='sm'>Key Type</Form.Label>
                        <span className="mandatory"> *</span>
                        <Form.Select
                          id='keyTypeId'
                          onChange={(e) => setKeyType(e.target.value)}
                          value={keyType}
                          disabled={uuidChecked}
                        >
                          <option disabled value=''>Key type...</option>
                          {KeyTypes.map((options, index) => (
                            <option key={index} value={options.value}>{options.select}</option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* <MDBRow className={keyType === 'Permanent' ? 'm-0 p-0' : 'hide'}>
              <Form.Group controlId="formBasicCheckbox" className='mb-3'>  
                <Row>
                  <Col md='6'>                      
                      <Form.Check 
                        type="radio" 
                        id='oneId'
                        label="1 Year" 
                        value={'1'}
                        onChange={(e) => setYears(e.target.value)}
                        checked={years === '1'}
                      />
                  </Col> 
                  <Col md='6'>                       
                      <Form.Check 
                        type="radio" 
                        id='twoId'
                        label="2 Years" 
                        value={'2'}
                        onChange={(e) => setYears(e.target.value)}
                        checked={years === '2'}
                      />
                  </Col>
                  <Col md='6'>                    
                      <Form.Check 
                        type="radio" 
                        id='threeId'
                        label="3 Years" 
                        value={'3'}
                        onChange={(e) => setYears(e.target.value)}
                        checked={years === '3'}
                      />
                  </Col> 
                  <Col md='6'>                    
                      <Form.Check 
                        type="radio" 
                        id='manId'
                        label="Manual" 
                        value={'4'}
                        onChange={(e) => setYears(e.target.value)}
                        checked={years === '4'}
                      />
                  </Col>  
                </Row>
              </Form.Group>                     
            </MDBRow> */}
                  {/*  <Row>
                    <Col md='6' className='c-column'>
                      <Form.Group className={keyType === '' ? 'hide-field' : 'mb-2'}>
                        <Form.Label className='white-lable me-0 pe-0'>User Count</Form.Label>
                        <span className="mandatory"> * </span>&nbsp;


                        <Form.Check
                          inline
                          label={<span className="white-label">Unlimited</span>}
                          type="checkbox"
                          id="unlimitedCheckbox"
                          checked={unlimited}
                          onChange={() => handleUnlimited(!unlimited)}
                          className="mr-3" // Add margin to the right of the checkbox
                        />

                        <Form.Control
                          type='number'
                          onChange={(e) => setUserCount(e.target.value)}
                          onKeyDown={handleKeyDown}
                          min={1}
                          max={maxUserCount}
                          id='uCountId'
                          required
                          value={userCount}
                          disabled={unlimited}
                          className="mr-2"
                        />
                      </Form.Group>
                    </Col>

                    <Col md='6' className='custom-column'>
                      <Form.Group className={keyType === 'Temporary' ? 'mb-2' : 'hide-field'}>
                        <Form.Label className='white-lable' size='sm'>Days</Form.Label>
                        <span className="mandatory"> *</span>
                        <Form.Select
                          id='daysId'
                          onChange={(e) => setDays(e.target.value)}
                          value={days}
                        >
                          <option disabled value=''>Days...</option>
                          {DaysOptions.map((options, index) => (
                            <option key={index} value={options.value}>{options.select}</option>
                          ))}
                        </Form.Select>
                      </Form.Group>

                      <Form.Group className={keyType === 'Permanent' ? 'mb-3' : 'hide-field'}>
                        <Form.Label className='white-lable' size='sm'>Years</Form.Label>
                        <span className="mandatory"> *</span>
                        <Form.Select
                          id='yearsId'
                          onChange={(e) => setSelectedYears(e.target.value)}
                          value={selectedYears}
                        >
                          <option disabled value=''>Years...</option>
                          {YearsOptions.map((options, index) => (
                            <option key={index} value={options.value}>{options.select}</option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row> */}
                  <Row>
                    <Col md='6' className='c-column'>
                      <Form.Group className={keyType === '' ? 'hide-field' : 'mb-2'}>
                        <Form.Label className='white-lable me-0 pe-0'>User Count</Form.Label>
                        <span className="mandatory"> * </span>&nbsp;

                        <Form.Check
                          inline
                          label={<span className="white-label">Unlimited</span>}
                          type="checkbox"
                          id="unlimitedCheckbox"
                          checked={unlimited || keyType === 'Perpetual'}
                          onChange={() => setUnlimited(!unlimited)}
                          disabled={keyType === 'Perpetual'}
                          className="mr-3"
                        />

                        <Form.Control
                          type='number'
                          onChange={(e) => setUserCount(e.target.value)}
                          onKeyDown={handleKeyDown}
                          min={1}
                          max={100} // Replace with your max value
                          id='uCountId'
                          required
                          value={userCount || (keyType === 'Perpetual' ? 50 : '')} // Set to 50 if keyType is 'Perpetual'
                          disabled={unlimited || keyType === 'Perpetual'} // Disable if unlimited is checked or keyType is 'Perpetual'
                          className="mr-2"
                        />
                      </Form.Group>
                    </Col>

                    <Col md='6' className='custom-column'>
                      <Form.Group className={(keyType === 'Permanent' || keyType === 'Perpetual') ? 'mb-3' : 'hide-field'}>
                        <Form.Label className='white-lable' size='sm'>Years</Form.Label>
                        <span className="mandatory"> *  </span>

                        {keyType === 'Perpetual' && (
                          <Form.Check
                            inline
                            label={<span className="white-label">Subscription</span>}
                            type="checkbox"
                            id="subscriptionCheckbox"
                            checked={subscription}
                            onChange={handleSubscription} // Handle the subscription checkbox
                            className="mr-3"
                          />
                        )}

                        {(keyType !== 'Perpetual' || subscription) && (
                          <Form.Select
                            id='yearsId'
                            onChange={(e) => setSelectedYears(e.target.value)}
                            value={selectedYears}
                            disabled={!subscription && keyType !== 'Permanent'}

                          >
                            <option disabled value=''>Years...</option>
                            {YearsOptions.map((options, index) => (
                              <option key={index} value={options.value}>{options.select}</option>
                            ))}
                          </Form.Select>
                        )}
                      </Form.Group>

                      <Form.Group className={keyType === 'Temporary' ? 'mb-2' : 'hide-field'}>
                        <Form.Label className='white-lable' size='sm'>Days</Form.Label>
                        <span className="mandatory"> *</span>
                        <Form.Select
                          id='daysId'
                          onChange={(e) => setDays(e.target.value)}
                          value={days}
                        >
                          <option disabled value=''>Days...</option>
                          {DaysOptions.map((options, index) => (
                            <option key={index} value={options.value}>{options.select}</option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md='12' className='cust-column'>
                      <Form.Group className='white-lable mb-3 me-0 pe-0'>
                        <Form.Label className='me-0 pe-0'>Description</Form.Label>
                        <Form.Control
                          as='textarea' // Use 'textarea' instead of 'text'
                          rows={4} // Specify the number of rows to increase the height
                          id='descriptionId'
                          onChange={(e) => setDescription(e.target.value)}
                          value={description}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row >
                    <Col md='12' className='cust-column'>
                      <h6 className='card-text mb-3'>Permanent: {available}</h6>
                    </Col>
                  </Row>
                  <Row >
                    <Col md='12' className='cust-column'>
                      <h6 className='card-text mb-3'>Perpetual: {perpetualAvailable}</h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col md='12' className='cust-column'>
                      <h6 className='card-text mb-3'>Temporary: {freeAvailable}</h6>
                    </Col>
                  </Row>
                  <Row className={!essentialBig ? 'mb-1' : 'hide'}>
                    <Col md='12' className='cust-column'>
                      <h6 className={keyValidate ? 'validate-h6 mb-0' : 'card-text mb-3'}>Needed Keys : {keys}</h6>
                      <span className={keyValidate ? 'validate' : 'hide'}>Needed keys exceeded available keys</span>
                    </Col>
                  </Row>
                  <Row className={essentialBig ? 'mb-1' : 'hide'}>
                    <Col md='12' className='mb-1'>
                      <h6 className={keyValidate ? 'validate-h6 mb-0' : 'card-text mb-3'}>{keys} keys will be added to your key wallet</h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col md='6' className='pt-2 ps-4'>
                      {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <Form.Check 
                    type="checkbox" 
                    id='freeCheckId'
                    label="Free" 
                    checked={isChecked}
                    disabled={available === 0 || freeAvailable === 0 ? true : false}
                    onChange={() => setIsChecked(!isChecked)}/>
                </Form.Group>   */}
                    </Col>
                    <Col md='6' className='d-flex justify-content-end mb-3 text-start remove-padding-right' >
                      {/* <Button
                        //disable the button when customer, keyType, userCount, months, days, uuid is empty
                        disabled={(customer.length === 0 || keyType === '' || checkMinMonths || userCount === '' || days === '0' || days === '' ||
                          selectedYears === '0' || selectedYears === '' || !editableUUID ||
                          keyValidate || uuid === '' || nullMonths || nullDays || !validUuid || sentRequest || isMaxUCount) ? true : false}
                        color='primary'
                        type='submit'
                        className={!isEditUuid && !editableUUID ? '' : 'hide'}
                        onClick={handleSubmit}
                      >
                        Assign Key
                      </Button>
                      <Button
                        //disable the button when customer, keyType, userCount, months, days, uuid is empty
                        disabled={(customer.length === 0 || keyType === '' || checkMinMonths || userCount === '' || days === '0' || days === '' || selectedYears === '0' || selectedYears === '' ||
                          keyValidate || uuid === '' || nullMonths || nullDays || !validUuid || sentRequest || isMaxUCount) ? true : false}
                        color='primary'
                        type='submit'
                        className={isEditUuid  ? '' : 'hide'}
                        onClick={handleEdit}
                      >
                        Update
              </Button> */}
                      <Button
                        disabled={(customer.length === 0 || keyType === '' || checkMinMonths || userCount === '' || days === '0' || days === '' ||
                          selectedYears === '0' || selectedYears === '' ||
                          keyValidate || uuid === '' || nullMonths || nullDays || !validUuid || sentRequest || isMaxUCount) ? true : false}
                        color='primary'
                        type='submit'
                        onClick={editableUUID ? handleEdit : handleSubmit}
                      >
                        {editableUUID ? 'Update' : 'Assign Key'}
                      </Button>

                    </Col>
                  </Row>
                </fieldset>
              </Form>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  )
}

export default Assign